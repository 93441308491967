export class WorkshopBudgetState {
  static readonly PENDING_ID = 1;
  static readonly APPOINTMENT_ID = 2;
  static readonly ADMITTED_ID = 3;
  static readonly DIAGNOSIS_ID = 4;
  static readonly PURCHASE_REQUESTED_ID = 5;
  static readonly REPAIR_ID = 6;
  static readonly FINISHED_ID = 7;
  static readonly DELIVERED_ID = 8;
  static readonly REJECTED_ID = 9;
  static readonly PENDING_LABEL = "Pendiente";
  static readonly APPOINTMENT_LABEL = "Turno";
  static readonly ADMITTED_LABEL = "Ingreso";
  static readonly DIAGNOSIS_LABEL = "Diagnóstico";
  static readonly PURCHASE_REQUESTED_LABEL = "Solicitud de Compra";
  static readonly REPAIR_LABEL = "Reparación";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly DELIVERED_LABEL = "Entregado";
  static readonly REJECTED_LABEL = "Rechazado";

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<WorkshopBudgetState>) {
    Object.assign(this, data);
  }
}
