import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import CommentBox from "./CommentBox";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import { CommentService } from "../../services/CommentService";
import { Comment } from "../../entities/comment.entity";
import { useAppSelector } from "../../redux-store/hooks";
import { ModelClassEnum } from "../../enum/model-class.enum";

interface ComponentProps {
  purchaseId: number;
}

export default function CommentSection({ purchaseId }: ComponentProps) {
  const service = new WorkshopPurchaseService();
  const commentService = new CommentService();
  const [comments, setComments] = useState<Comment[]>([]);
  const user = useAppSelector((state) => state.userReducer.user);

  const handleAddComment = async (newComment: Comment, attachments: any) => {
    try {
      const storedComment = await commentService.store(
        newComment,
        ModelClassEnum.WORKSHOP_PURCHASE,
        purchaseId,
        attachments
      );

      if ("errors" in storedComment) {
        alert("No se pudo guardar el comentario.");
        return;
      }

      setComments([storedComment, ...comments]);
    } catch (error) {
      console.error("Error al guardar el comentario:", error);
    }
  };

  useEffect(() => {
    service.getComments(purchaseId).then((comments) => {
      const sortedComments = comments.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setComments(sortedComments);
    });
  }, [purchaseId]);

  return (
    <div>
      <CommentForm onAddComment={handleAddComment} userName={user.name} />
      {comments.map((c) => (
        <CommentBox comment={c} key={c.id} />
      ))}
    </div>
  );
}
