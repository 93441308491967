import ApiService from "./ApiService";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { WorkshopPurchaseState } from "../entities/workshop-purchase-state.entity";

type Params = {};

export class WorkshopPurchaseStateService
  implements BaseApplicationService<WorkshopPurchaseState, Params>
{
  findOneById(_id: number): Promise<WorkshopPurchaseState | undefined> {
    throw new Error("Method not implemented.");
  }
  findOne(_params: Params): Promise<WorkshopPurchaseState> {
    throw new Error("Method not implemented.");
  }
  async find(_params: Params): Promise<WorkshopPurchaseState[]> {
    const response = await ApiService.get("/api_v2/workshop-purchase-state");

    return (
      response?.data.data.map(
        (p: WorkshopPurchaseState) => new WorkshopPurchaseState(p)
      ) || []
    );
  }
}
