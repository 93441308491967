import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import PopupState, { bindToggle, bindMenu } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { UserNotificationService } from "../services/UserNotificationService";
import { formatDistance, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { Box, Divider, Switch, Typography } from "@mui/material";

interface Notification {
  id: number;
  message: string;
  viewed: number;
  created_at: string;
  created_by: number;
  url: string;
  notification_id: string | null;
  user_id: number;
  user_notification_category_id: number;
}

export default function NotificationBell() {
  const service = new UserNotificationService();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notViewedCount, setNotViewedCount] = useState<number>(0);
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);

  const getNotifications = async (page: number) => {
    try {
      return await service.getNotifications(page);
    } catch (error) {
      console.error("Error al obtener notificaciones:", error);
      return []; // Devuelve un arreglo vacío en caso de error
    }
  };

  const onClickNotification = async (notification: any) => {
    if (notification.url.includes(window.location.origin)) {
      window.location = notification.url;
    } else {
      window.open(notification.url);
    }

    if (notification.viewed == false) {
      try {
        const operationResponse = await service.setAsViewed(notification.id);
        if (!operationResponse) {
          return;
        }
      } catch (e) {
        console.error(e);
        return;
      }
      setNotifications((prevVal: any) => {
        return prevVal.map((n: any) => {
          if (n.id === notification.id) {
            return { ...n, viewed: true };
          }

          return n;
        });
      });
      if (notViewedCount > 0) {
        setNotViewedCount((prevVal) => prevVal - 1);
      }
    }
  };

  const getTimeDistanceMessage = (sourceDate: any) => {
    // Assuming that sourceDate has next format: "YYYY-MM-DD HH:mm:ss"
    // TODO: REMOVE IT USING STANDARD DATE FROM BACKEND
    const currenDate = new Date();
    const messageDate = parseISO(sourceDate.replace(" ", "T") + "Z");
    const calculatedMessage = formatDistance(currenDate, messageDate, {
      locale: es,
    });

    return `Hace ${calculatedMessage}`;
  };

  useEffect(() => {
    const callbackFunction = () => {
      service.getNotViewedCount().then((count) => {
        if (count != notViewedCount) {
          setNotViewedCount(count || 0);
        }
      });
    };
    const checkNotViewedInterval = setInterval(callbackFunction, 60000);
    callbackFunction();

    return () => {
      clearInterval(checkNotViewedInterval);
    };
  }, []);

  useEffect(() => {
    getNotifications(1)
      .then((n: Notification[]) => {
        if (Array.isArray(n)) {
          setNotifications(n);
        } else {
          console.error(
            "La respuesta de getNotification no es un arreglo: ",
            n
          );
          setNotifications([]);
        }
      })
      .catch((error) => {
        console.error("Error al obtener notificaciones: ", error);
        setNotifications([]);
      });
  }, [notViewedCount]);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Badge
            badgeContent={notViewedCount}
            color="success"
            {...bindToggle(popupState)}
          >
            <NotificationsIcon
              sx={{ width: "100%", cursor: "pointer" }}
            ></NotificationsIcon>
          </Badge>
          <Menu {...bindMenu(popupState)}>
            <Box
              m={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MenuItem>Notificaciones ({notViewedCount})</MenuItem>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>
                  Solo no leídas
                </Typography>
                <Switch
                  checked={showOnlyUnread}
                  onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                />
              </Box>
            </Box>
            <Divider />

            {notifications.length === 0 && (
              <MenuItem onClick={popupState.close}>Sin notificaciones</MenuItem>
            )}

            {Array.isArray(notifications) &&
              notifications.map((n: any) => {
                if (showOnlyUnread && n.viewed) {
                  return null;
                }
                return (
                  <>
                    <MenuItem
                      key={n.id}
                      onClick={() => onClickNotification(n)}
                      sx={{
                        backgroundColor: !n.viewed
                          ? "rgba(64, 122, 214, 0.16)"
                          : "none",
                        borderRadius: "5px",
                        margin: 1,
                        ":hover": {
                          backgroundColor: !n.viewed
                            ? "#var(--ds-background-neutral-subtle-hovered,#F4F5F7)"
                            : "none",
                        },
                      }}
                    >
                      {n.message}
                      <br />
                      {getTimeDistanceMessage(n.created_at)}
                    </MenuItem>
                    <Divider />
                  </>
                );
              })}
          </Menu>
        </>
      )}
    </PopupState>
  );
}
