import { enqueueSnackbar } from "notistack";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import { WorkshopPurchaseState } from "../../../../entities/workshop-purchase-state.entity";

export const onDragEndPurchase = async (
  result: any,
  columns: any,
  setColumns: any,
  setItems: any,
  items: WorkshopPurchase[]
) => {
  const purchaseService = new WorkshopPurchaseService();

  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [movedItem] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, movedItem);

    const movedPurchase = items.find(
      (purchase) => purchase.id === parseInt(movedItem.id)
    );

    if (
      destColumn.id === WorkshopPurchaseState.QUOTED_ID &&
      movedPurchase &&
      !movedPurchase.workshopPurchaseItems.every((item) => item.price !== null)
    ) {
      enqueueSnackbar(
        "No se puede mover al estado 'Cotizado' sin definir todos los precios",
        { variant: "error" }
      );

      sourceItems.splice(destination.index, 0, movedItem);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
      });

      return;
    }

    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    };

    const previousColumns = { ...columns };
    setColumns(newColumns);

    const movedPurchaseForUpdate = items.find(
      (purchase) => purchase.id === parseInt(movedItem.id)
    );

    if (movedPurchaseForUpdate) {
      try {
        const newStateId = destColumn.id;

        if (!newStateId) {
          console.error("El estado de la compra es inválido");
          setColumns(previousColumns);
          return;
        }

        const updatedPurchase = await purchaseService.update(
          movedPurchaseForUpdate.id,
          {
            workshop_purchase_state_id: newStateId,
          }
        );

        setItems((prevPurchases: WorkshopPurchase[]) =>
          prevPurchases.map((purchase) =>
            purchase.id === updatedPurchase.id ? updatedPurchase : purchase
          )
        );

        enqueueSnackbar("Se actualizó el estado de la compra", {
          variant: "success",
        });
      } catch (error) {
        console.error("Error updating purchase state:", error);
        setColumns(previousColumns);
        enqueueSnackbar("Error al actualizar el estado de la compra", {
          variant: "error",
        });
      }
    }
  }
};
