import { useRef, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import Webcam from "react-webcam";

interface BarcodeScannerProps {
  onDetected: (result: string) => void;
}

export default function BarcodeScanner({ onDetected }: BarcodeScannerProps) {
  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    let intervalId: NodeJS.Timeout;

    const scanBarcode = async () => {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          try {
            const result = await codeReader.decodeFromImage(
              undefined,
              imageSrc
            );
            if (result) {
              onDetected(result.getText());
            }
          } catch (error) {
            // No barcode found, continue scanning
          }
        }
      }
    };

    intervalId = setInterval(scanBarcode, 500); // Scan every 500ms

    return () => {
      clearInterval(intervalId);
    };
  }, [onDetected]);

  return (
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      width="100%"
    />
  );
}
