import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface ComponentProps {
  to: string;
  children: React.ReactNode;
}

export default function AppLink({ to, children }: ComponentProps) {
  return (
    <Link component={RouterLink} to={to} underline="none" color="inherit">
      {children}
    </Link>
  );
}
