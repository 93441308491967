import { User } from "./user.entity";

export class Comment {
  readonly id!: number;
  readonly budget_id!: number;
  readonly request_id!: number;
  readonly workshop_purchase_id!: number;
  readonly body!: string;
  readonly created_by!: number;
  readonly created_at!: Date;
  readonly createdBy!: User;
  readonly commentAttachments!: {
    id: number;
    comment_id: number;
    file: string;
  }[];

  constructor(partialComment: Partial<Comment>) {
    Object.assign(this, partialComment);
  }
}
