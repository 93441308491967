import { Box, Modal } from "@mui/material";
import ModalDetailBudget from "./ModalDetailBudget";
import { useContext } from "react";
import { BudgetContext } from "./contexBudget/BudgetContext";
import { Budget } from "../../../entities/budget.entity";

const BudgetModal = () => {
  const { open, setOpen, selectedBudget, updateBudget, setSelectedBudget } =
    useContext(BudgetContext);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        {selectedBudget && (
          <ModalDetailBudget
            budget={selectedBudget}
            handleClose={handleClose}
            onBudgetChange={(budget: Budget) => {
              updateBudget(budget.id, budget).then(() => {
                setSelectedBudget(budget);
              });
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default BudgetModal;
