import React, { useState, ChangeEvent } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { Comment } from "../../entities/comment.entity";

interface CommentFormProps {
  onAddComment: (comment: Comment, attachments: any) => void;
  userName: string;
}

const CommentForm: React.FC<CommentFormProps> = ({
  onAddComment,
  userName,
}) => {
  const [content, setContent] = useState("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [requiredContent, setRequiredContent] = useState(false);

  const handleAddComment = async () => {
    if (content == "") {
      setRequiredContent(true);
      return false;
    }
    setRequiredContent(false);

    const newComment = new Comment({
      body: content,
    });
    const filesElement = document.getElementById(
      "comment-images"
    ) as HTMLInputElement;
    onAddComment(newComment, filesElement.files);
    setContent("");
    setImageUrls([]);
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImageUrls: string[] = await Promise.all(
        Array.from(files).map(async (file) => {
          const imageUrl = await new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
          return imageUrl;
        })
      );
      setImageUrls([...imageUrls, ...newImageUrls]);
    }
  };

  return (
    <Card sx={{ marginBottom: "50px" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <CardHeader avatar={<Avatar>{userName[0]}</Avatar>} />
          <TextField
            label="Comentario"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            fullWidth
            error={requiredContent}
            helperText={requiredContent ? "Este campo es requerido" : ""}
            multiline
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="comment-images"
                    type="file"
                    multiple
                    onChange={handleImageChange}
                  />
                  <label htmlFor="comment-images">
                    <IconButton color="primary" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ display: "flex", overflow: "auto" }}>
          {imageUrls.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Adjunto ${index + 1}`}
              style={{ maxWidth: "100px", margin: "10px", maxHeight: "100px" }}
            />
          ))}
        </div>
        <Button
          onClick={handleAddComment}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          Agregar Comentario
        </Button>
      </CardContent>
    </Card>
  );
};

export default CommentForm;
