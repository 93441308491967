import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";

import { WorkshopPurchaseItemService } from "../../../services/WorkshopPurchaseItemService";
import { WorkshopPurchaseItemState } from "../../../entities/workshop-purchase-item-state.entity";
import { FormattedNumber } from "react-intl";
import AppToolbarExport from "../../Common/DataGrid/AppToolbarExport";
import { parseUTCDate } from "../../../utils/dateUtils";
import "../../../index.css";
import { PurchaseContext } from "./context/PurchaseContext";
import { User } from "../../../entities/user.entity";
import { WorkshopService } from "../../../services/WorkshopService";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <AppToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [responsibleList, setResponsibleList] = useState<User[]>([]);
  const { purchases } = useContext(PurchaseContext);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(() => {
      const visibilityModel: GridColumnVisibilityModel = {};
      WorkshopPurchaseItemState.getIdAndLabels().forEach((i: any) => {
        visibilityModel[`state_${i.id}`] = false;
      });
      return visibilityModel;
    });

  const service = new WorkshopPurchaseItemService();
  const workshopService = new WorkshopService();

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await workshopService.users(purchases[0]?.workshop.id);
      setResponsibleList(users);
    };

    if (purchases.length > 0) {
      fetchUsers();
    }
  }, [purchases]);

  useEffect(() => {
    const fetchResponsibleList = async () => {
      if (rows.length > 0 && purchases.length > 0) {
        try {
          const users = await workshopService.users(purchases[0].workshop.id);
          setResponsibleList(users);
        } catch (error) {
          console.error("Error fetching responsible list:", error);
        }
      }
    };

    fetchResponsibleList();
  }, [rows, purchases]);

  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      setRows([]);

      try {
        const result = await service.getHistoricalReportData(
          page + 1,
          pageSize
        );

        if (result && "data" in result && "totalCount" in result) {
          setRows(result.data);
          setTotalCount(result.totalCount);
        } else {
          console.warn(
            "Resultado inesperado de getHistoricalReportData:",
            result
          );
        }
      } catch (error) {
        console.error("Error fetching historical report data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [page, pageSize]);

  const handlePaginationModelChange = (paginationModel: {
    page: number;
    pageSize: number;
  }) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const initialColumns: GridColDef[] = [
    { field: "workshop_purchase_id", headerName: "Compra", flex: 1 },
    {
      field: "budgetItem",
      headerName: "Presupuesto",
      flex: 1,
      valueGetter: (params) => params.row.budgetItem.budget_id,
    },
    {
      field: "id",
      headerName: "Item ID",
      flex: 1,
    },
    {
      field: "repairSubitem",
      headerName: "Nombre de Item",
      flex: 1,
      valueGetter: (params) => params.row.budgetItem.repairSubitem?.name,
    },
    {
      field: "price",
      headerName: "Precio",
      flex: 1,
      renderCell: (params) => (
        <FormattedNumber
          value={params.row.price}
          style="currency"
          currency="ARS"
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      flex: 1,
      renderCell: (params) => <FormattedNumber value={params.row.quantity} />,
    },
    {
      field: "brand",
      headerName: "Marca",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      flex: 1,
      valueGetter: (params) => {
        const purchase = purchases.find(
          (p) => p.id === params.row.workshop_purchase_id
        );

        if (purchase) {
          const responsible = responsibleList.find(
            (r) => r.id === purchase.responsible_id
          );
          return responsible
            ? `${responsible.name} ${responsible.lastname}`
            : "";
        }
        return "";
      },
    },
    {
      field: "budget_expiration_days",
      headerName: "Días de expiración",
      flex: 1,
      valueGetter: (params) => {
        const purchase = purchases.find(
          (p) => p.id === params.row.workshop_purchase_id
        );
        if (purchase) {
          return purchase.budget.expiration_days || "";
        }

        return "";
      },
    },
  ];

  const getColumns = () => {
    return initialColumns.concat(
      WorkshopPurchaseItemState.getIdAndLabels().map((i: any) => ({
        field: `state_${i.id}`,
        headerName: i.label,
        flex: 1,
        cellClassName: "hoverable-column",
        valueGetter: (params) => {
          const stateValue = params.row[`state_${i.id}`];
          return stateValue ? parseUTCDate(stateValue) : "";
        },
      }))
    );
  };

  return (
    <>
      <h2>Reporte Item de Compras</h2>
      <DataGrid
        sx={{ minHeight: "300px" }}
        loading={loading}
        rows={rows}
        columns={getColumns()}
        slots={{
          toolbar: CustomToolbar,
        }}
        pagination
        paginationMode="server"
        rowCount={totalCount}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
    </>
  );
}
