import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import LAXLOGO from "../img/LAXLOGO.png";

export default function MobileNavBar({
  ListContent,
}: {
  ListContent: JSX.Element;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={open ? "close drawer" : "open drawer"}
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography>PGT</Typography>
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box role="presentation" width="200px">
          <Box className="center-text">
            <img
              src={LAXLOGO}
              alt="Logo"
              style={{
                width: `80px`,
                height: "auto",
              }}
            />
          </Box>

          {ListContent}
        </Box>
      </Drawer>
    </>
  );
}
