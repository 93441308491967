import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/x-data-grid";

export const AppTheme = createTheme(
  {
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
    palette: {
      primary: {
        main: "#D61432",
        light: "#DD3B54",
        dark: "#B6112B",
      },
      secondary: {
        main: "#0101F9",
        light: "#2B2BFA",
        dark: "#0101D0",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
      },
    },
  },
  esES
);

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#D61432",
    },
    secondary: {
      main: "#0101F9",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#407AD6",
    },
    secondary: {
      main: "#1c252e",
    },
    background: {
      default: "#141A21",
      paper: "#1C252E",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1C252E",
          backgroundImage: "none",
        },
      },
    },
  },
});
