import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { Comment } from "../entities/comment.entity";
import ApiService from "./ApiService";
import { FieldErrors } from "../types/FieldError";
import { ModelClassEnum } from "../enum/model-class.enum";

type Params = {};

export class CommentService implements BaseApplicationService<Comment, Params> {
  findOneById(_id: number): Promise<Comment | undefined> {
    throw new Error("Method not implemented.");
  }
  findOne(_params: Params): Promise<Comment> {
    throw new Error("Method not implemented.");
  }
  find(_params?: Params | undefined): Promise<Comment[]> {
    throw new Error("Method not implemented.");
  }

  async store(
    comment: Partial<Comment>,
    model_class: ModelClassEnum,
    model_id: number,
    attachments: any[]
  ): Promise<Comment | FieldErrors> {
    const formData = new FormData();

    formData.append("model_class", model_class);
    formData.append("model_id", model_id.toString());
    formData.append("body", comment.body || "");

    for (let i = 0; i < attachments.length; i++) {
      formData.append("Comment[image][]", attachments[i]);
    }

    const storedComment = await ApiService.post(
      `/api_v2/comment/create`,
      formData
    );

    if ("errors" in storedComment) {
      return { errors: storedComment.data.errors };
    }

    return new Comment(storedComment.data.data);
  }

  public static async getAttachment(
    id: number,
    index: number,
    profile?: string
  ): Promise<Blob> {
    const url = new URL(
      `${
        import.meta.env.VITE_API_HOST
      }/api_v2/comment/download-attachment?id=${id}&index=${index}`
    );

    if (profile) {
      url.searchParams.set("profile", profile);
    }

    const response = await ApiService.get(url.toString(), {
      responseType: "blob",
    });

    return response.data;
  }
}
