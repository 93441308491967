import React, { createContext } from "react";
import { Budget } from "../../../../entities/budget.entity";

export type BudgetProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pagedBudgets: Budget[];
  activeBudgets: Budget[];
  selectedBudget: Budget | undefined;
  setSelectedBudget: React.Dispatch<React.SetStateAction<Budget | undefined>>;
  loading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  updateBudget: (
    budgetId: number,
    updatedBudget: Partial<Budget>
  ) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  totalCount: number;
  pageSize: number;
};

export const BudgetContext = createContext<BudgetProps>({
  open: false,
  setOpen: () => {},
  activeBudgets: [],
  pagedBudgets: [],
  selectedBudget: undefined,
  setSelectedBudget: () => {},
  loading: true,
  fetchNextPage: () => {},
  hasNextPage: undefined,
  isFetchingNextPage: false,
  updateBudget: async () => {},
  setFilters: () => {},
  totalCount: 0,
  pageSize: 20,
});
