import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  Modal,
} from "@mui/material";
import "../Purchase/Index.css";

import SearchBar from "./SearchBar";
import ProductForm from "./ProductForm";
import ProductDetail from "./ProductDetail";
import { useDarkMode } from "../../ThemeContext";

// Datos de muestra
const sampleProducts = [
  {
    id: 1,
    title: "Batería Automotriz",
    category: "Baterías",
    description: "Batería de 12V para autos.",
    price: 120,
    barcode: "1234567890121",
    sellingPrice: 130,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLW-7PKeAGO8EL_6ZmB4YMZ-oPQO_0G7kf0w&s",
    count: 50,
  },
  {
    id: 2,
    title: "Aceite de Motor 5W-30",
    category: "Aceites",
    description: "Aceite sintético 5W-30 para motores de gasolina.",
    price: 40,
    barcode: "1234567890122",
    sellingPrice: 45,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIwOqeR6R8I6SA7Qb-woqLFcqrlV1jmJDsig&s",
    count: 150,
  },
  {
    id: 3,
    title: "Correa Poli V",
    category: "Correas",
    description: "Correa Poli V para vehículos automotrices.",
    price: 25,
    barcode: "1234567890123",
    sellingPrice: 30,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRApxzwnfGmOgMyEywEik9croG6DrkVTxAaWA&s",
    count: 100,
  },
  {
    id: 4,
    title: "Filtro de Aire",
    category: "Filtros",
    description: "Filtro de aire para motores de combustión interna.",
    price: 15,
    barcode: "1234567890124",
    sellingPrice: 18,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8GTOmjJkWNcpNS0slkzLaG3DLW-rC8cR4Zw&s",
    count: 200,
  },
  {
    id: 5,
    title: "Pastillas de Freno",
    category: "Frenos",
    description: "Juego de pastillas de freno para autos.",
    price: 35,
    barcode: "1234567890125",
    sellingPrice: 40,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2FFRt4qmfwSigc7vJocuYD95EdgaObTNnzw&s",
    count: 75,
  },
  {
    id: 6,
    title: "Bujías",
    category: "Encendido",
    description: "Bujías de iridio para motores de gasolina.",
    price: 10,
    barcode: "1234567890126",
    sellingPrice: 12,
    image: "https://www.moserline.com.ar/images/5dd1e-bosch_wr7ds.webp",
    count: 300,
  },
  {
    id: 7,
    title: "Filtro de Combustible",
    category: "Filtros",
    description: "Filtro de combustible para motores de combustión interna.",
    price: 20,
    barcode: "1234567890127",
    sellingPrice: 25,
    image:
      "https://cdn.club-magazin.autodoc.de/uploads/sites/11/2021/01/filtro-de-gasolina.jpg",
    count: 120,
  },
  {
    id: 8,
    title: "Amortiguadores",
    category: "Suspensión",
    description: "Juego de amortiguadores para autos.",
    price: 80,
    barcode: "1234567890128",
    sellingPrice: 90,
    image:
      "https://m.media-amazon.com/images/I/61FN96HEbJS._AC_UF894,1000_QL80_.jpg",
    count: 60,
  },
  {
    id: 9,
    title: "Bomba de Agua",
    category: "Refrigeración",
    description: "Bomba de agua para sistema de refrigeración del motor.",
    price: 45,
    barcode: "1234567890129",
    sellingPrice: 50,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL_hKfCg8kw_SpcyzKGa8q-_xZKh-CV7n51Q&s",
    count: 85,
  },
  {
    id: 10,
    title: "Alternador",
    category: "Sistema Eléctrico",
    description: "Alternador de alta capacidad para autos.",
    price: 150,
    barcode: "1234567890130",
    sellingPrice: 160,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH_SRnlQqdON2bQWNvBAZ2nQ3vt4a72MzYQQ&s",
    count: 40,
  },
];

export default function ProductList() {
  const [products, _setProducts] = useState<any[]>(sampleProducts);
  const [filteredProducts, setFilteredProducts] = useState<any[]>(products);
  const [open, setOpen] = useState(false);
  const [openPorduct, setOpenProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<undefined | any>(
    undefined
  );
  const { isDarkMode } = useDarkMode();

  const handleOpenModal = (product: any) => {
    setSelectedProduct(product);
    setOpenProduct(true);
  };

  const handleCloseModal = () => {
    setOpenProduct(false);
    setSelectedProduct(undefined);
  };

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleFilteredProducts = (filtered: any[]) => {
    setFilteredProducts(filtered);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: isDarkMode ? "#141A21" : "#FCF0F2",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
    minWidth: "400px",
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "title", headerName: "Producto", flex: 1 },
    { field: "category", headerName: "Categoria", flex: 0.5 },
    { field: "description", headerName: "Descripción", flex: 1 },
    { field: "barcode", headerName: "Codigo", flex: 1 },
    {
      field: "count",
      headerName: "Cantidad",
      flex: 0.3,
      valueGetter: (params) => {
        return params.row.count || 2;
      },
    },
    {
      field: "image",
      headerName: "Imagen",
      flex: 0.6,
      renderCell: (params) => (
        <ListItemAvatar>
          <Avatar alt={params.row.title} src={params.value} />
        </ListItemAvatar>
      ),
    },
  ];

  const getRowId = (product: any) => product.id;

  // if (loading) return <CircularProgress />;
  // if (error) return <div>{error}</div>;

  const handleClose = () => setOpen(false);

  return (
    <>
      <h2>Items</h2>
      <Box
        sx={{ display: "flex", alignContent: "center", alignItems: "center" }}
      >
        <SearchBar
          products={products}
          onFilteredProducts={handleFilteredProducts}
        />
        <InputAdornment sx={{ mb: 2 }} position="end">
          <IconButton onClick={() => setOpen(true)}>
            <AddIcon />
          </IconButton>
        </InputAdornment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <ProductForm />
            </Box>
          </Box>
        </Modal>
      </Box>

      <DataGrid
        // loading={loading}
        slotProps={{ row: { style: { cursor: "pointer" } } }}
        rows={filteredProducts}
        columns={columns}
        getRowId={getRowId}
        onRowClick={(params) => handleOpenModal(params.row)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
      />
      {selectedProduct && (
        <Modal
          open={openPorduct}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ProductDetail product={selectedProduct} />
        </Modal>
      )}
    </>
  );
}
