export class WorkshopPurchaseState {
  static readonly QUOTE_ID = 1;
  static readonly QUOTED_ID = 2;
  static readonly BUY_ID = 3;
  static readonly SPEC_ID = 4;
  static readonly FINISHED_ID = 5;
  static readonly DELIVERED_ID = 6;
  static readonly CANCELLED_ID = 7;
  static readonly EXPIRED_ID = 8;
  static readonly REJECTED_ID = 9;
  static readonly QUOTE_LABEL = "Cotizar";
  static readonly QUOTED_LABEL = "Cotizado";
  static readonly BUY_LABEL = "Comprar";
  static readonly DELIVERED_LABEL = "Entregado";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly SPEC_LABEL = "SPEC";
  static readonly CANCELLED_LABEL = "Cancelado";
  static readonly EXPIRED_LABEL = "Vencida";
  static readonly REJECTED_LABEL = "Rechazada";

  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<WorkshopPurchaseState>) {
    Object.assign(this, data);
  }
}
