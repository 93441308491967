import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { login, setToken } from "../../../services/AuthService";
import { useAppDispatch } from "../../../redux-store/hooks";
import { setUser } from "../../../redux-store/slices/UserSlice";
import { handleEnter } from "../../../services/KeyboardService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PasswordIcon from "@mui/icons-material/Password";
import Logo from "../../../img/Logo_Login.svg";
import { useDarkMode } from "../../ThemeContext";

export default function Signin() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedPass, setIsFocusedPass] = useState(false);
  const theme = useTheme();
  const { isDarkMode } = useDarkMode();

  const handleLogin = async () => {
    if (!username || !password) {
      alert("Por favor ingrese usuario y contraseña.");
      return;
    }

    setLoading(true);

    const auth = await login(username, password);

    if (auth === false) {
      alert("No tienes acceso a esta aplicación");
      setLoading(false);
      return;
    }

    if (auth === undefined) {
      alert("Usuario y/o contraseña incorrecto.");
      setLoading(false);
      return;
    }

    setToken(auth.token);

    dispatch(setUser(auth));

    setLoading(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "60% 40%" }}>
      <Box
        sx={{
          background: isDarkMode
            ? "linear-gradient(#141A21, #273849, #324960)"
            : "linear-gradient(#D61432, #8A0202, #790202)",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              [theme.breakpoints.up("xl")]: { fontSize: "2rem" },
            }}
          >
            LAX Mantenimiento de flotas
          </Typography>
          <Typography sx={{ color: "white" }}>
            Solución 360 para optimizar la gestión de flotas y reducir costos.
          </Typography>
          <Typography sx={{ color: "white" }}>
            +10.000 vehículos gestionados.
          </Typography>
          <Typography sx={{ color: "white" }}>
            +6.000 presupuestos por mes.
          </Typography>
          <Typography sx={{ color: "white" }}>
            +500 proveedores a nivel país.
          </Typography>
          <Button
            href="https://www.laxargentina.com/contacto"
            target="_blank"
            sx={{ marginTop: 2, fontWeight: "300" }}
            variant="contained"
          >
            Solicitar demo
          </Button>
        </Box>
        <Box>
          <Box
            sx={{
              width: "500px",
              height: "500px",
              border: isDarkMode ? "1px solid #141A21" : "1px solid #D61432",
              borderRadius: "50%",
              position: "absolute",
              transform: "translate(-158%, 22%)",
              [theme.breakpoints.up("xl")]: {
                transform: "translate(-180%, 36%)",
                width: "550px",
                height: "550px",
              },
            }}
          ></Box>
          <Box
            sx={{
              width: "500px",
              height: "500px",
              border: isDarkMode ? "1px solid #141A21" : "1px solid #D61432",
              borderRadius: "50%",
              position: "absolute",
              transform: "translate(-144%, 26%)",
              [theme.breakpoints.up("xl")]: {
                transform: "translate(-163%, 40%)",
                width: "550px",
                height: "550px",
              },
            }}
          ></Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${Logo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: 87.04,
            height: 85.26,
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        ></Box>
        <Box
          sx={{
            minWidth: "300px",
            [theme.breakpoints.up("xl")]: {
              minWidth: "450px",
            },
          }}
          className="default-section center-text"
        >
          <Stack spacing={2}>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                [theme.breakpoints.up("xl")]: {
                  fontSize: "2rem",
                },
              }}
            >
              Iniciar Sesión
            </Typography>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel
                htmlFor="username-input"
                shrink={isFocused || !!username}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!isFocused && !username && (
                  <AccountBoxIcon fontSize="small" sx={{ marginRight: 1 }} />
                )}
                Usuario
              </InputLabel>
              <OutlinedInput
                id="username-input"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                label="Usuario"
              />
            </FormControl>

            <FormControl size="small" fullWidth>
              <InputLabel
                htmlFor="outlined-adornment-password"
                shrink={isFocusedPass || !!password}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!isFocusedPass && !password && (
                  <PasswordIcon fontSize="small" sx={{ marginRight: 1 }} />
                )}
                Contraseña
              </InputLabel>
              <OutlinedInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleEnter(e, handleLogin)}
                onFocus={() => setIsFocusedPass(true)}
                onBlur={() => setIsFocusedPass(false)}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <LoadingButton
              loading={loading}
              onClick={handleLogin}
              variant="contained"
            >
              Ingresar
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
