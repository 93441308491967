import {
  DataGrid,
  GridColDef,
  GridRowClassNameParams,
  GridSortModel,
} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import ModalDetail from "../../Purchase/ModalDetail";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";

import "./Index.css";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import PriorityIcon from "../../Purchase/PriorityIcon";
import { Car } from "../../../entities/car.entity";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { PurchaseContext } from "./context/PurchaseContext";
import { WorkshopPurchaseState } from "../../../entities/workshop-purchase-state.entity";
import { useDarkMode } from "../../ThemeContext";

export default function Backlog() {
  const [sortedPurchases, setSortedPurchases] = useState<WorkshopPurchase[]>(
    []
  );
  const [anchoredRows, setAnchoredRows] = useState<number[]>([]);
  const [sortModel] = useState<GridSortModel>([]);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const ascending = "asc";
  const descending = "desc";

  const [page, setPage] = useState<number>(0);

  const { open, setOpen } = useContext(PurchaseContext);
  const {
    selectedPurchase,
    setSelectedPurchase,
    purchases,
    updatePurchase,
    totalCount,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    pageSize,
    loading,
  } = useContext(PurchaseContext);

  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const storedAnchoredRows = localStorage.getItem("anchoredRows");
    if (storedAnchoredRows) {
      setAnchoredRows(JSON.parse(storedAnchoredRows));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("anchoredRows", JSON.stringify(anchoredRows));
  }, [anchoredRows]);

  useEffect(() => {
    const anchoredPurchases = purchases.filter((p) =>
      anchoredRows.includes(p.id)
    );

    const currentPagedPurchases = purchases.slice(
      page * pageSize,
      (page + 1) * pageSize
    );

    const unanchoredPurchases = currentPagedPurchases.filter(
      (p) => !anchoredRows.includes(p.id)
    );

    const combinedPurchases = [...anchoredPurchases, ...unanchoredPurchases];

    setSortedPurchases(combinedPurchases.slice(0, pageSize));
  }, [anchoredRows, purchases, page, pageSize]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.6 },
    {
      field: "workshop_purchase_priority_id",
      headerName: "Prioridad",
      flex: 1.3,
      renderCell: (params) => (
        <>
          {params.row.workshopPurchasePriority?.name || ""}
          {params.row.workshopPurchasePriority?.id !== undefined && (
            <PriorityIcon id={params.row.workshopPurchasePriority.id} />
          )}
        </>
      ),
      valueGetter: (params) => {
        return params.row.workshopPurchasePriority?.id;
      },
    },
    {
      field: "workshop",
      headerName: "Taller",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.workshop.name}`;
      },
    },
    {
      field: "budget_id",
      headerName: "N° de presupuesto",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.id}`;
      },
    },
    {
      field: "budget_company_name",
      headerName: "Cliente",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.company.name}`;
      },
    },
    {
      field: "car_identification",
      headerName: "Unidad",
      flex: 1,
      valueGetter: (params) => {
        return Car.getSummary(params.row.budget.car);
      },
    },
    {
      field: "car_color",
      headerName: "Color",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.car.color}`;
      },
    },
    {
      field: "car_usage",
      headerName: "KM",
      flex: 0.3,
      valueGetter: (params) => {
        return `${params.row.budget.car.usage}`;
      },
    },
    {
      field: "car_engine_number",
      headerName: "N° Motor",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.car.engine_number}`;
      },
    },
    {
      field: "car_chassis_number",
      headerName: "N° Chasis",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.budget.car.chassis_number}`;
      },
    },
    {
      field: "created_at",
      headerName: "Fecha de solicitud",
      flex: 1,
    },
    {
      field: "is_car_in_workshop",
      headerName: "En taller",
      flex: 0.6,
      renderCell: (params) => (
        <>{`${params.row.budget.in_workshop ? "Si" : "No"}`}</>
      ),
      valueGetter: (params) => {
        return `${params.row.budget.in_workshop}`;
      },
    },
    {
      field: "workshop_purchase_state_name",
      headerName: "Estado",
      flex: 1.2,
      valueGetter: (params) => {
        const state = params.row.workshopPurchaseState.name;
        let color = "";
        let background = "";

        switch (state) {
          case WorkshopPurchaseState.QUOTE_LABEL:
          case WorkshopPurchaseState.BUY_LABEL:
            color = "#d97706"; //amarillo
            background = "#fef3c7";
            break;
          case WorkshopPurchaseState.QUOTED_LABEL:
            color = "#2563eb"; //azul
            background = "#dbeafe";
            break;
          case WorkshopPurchaseState.FINISHED_LABEL:
          case WorkshopPurchaseState.DELIVERED_LABEL:
            color = "#059669"; // Verde
            background = "#d1fae5";
            break;
          case WorkshopPurchaseState.SPEC_LABEL:
          case WorkshopPurchaseState.CANCELLED_LABEL:
          case WorkshopPurchaseState.EXPIRED_LABEL:
          case WorkshopPurchaseState.REJECTED_LABEL:
            color = "#6b7280"; // Gris
            background = "#d1d5db";
            break;
          default:
            color = "";
        }
        return { state, color, background };
      },
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value.color,
            background: params.value.background,
            borderRadius: "5px",
            padding: "2px 3px 2px 3px",
          }}
        >
          {params.value.state}
        </Typography>
      ),
    },

    {
      field: "anchored",
      headerName: "Anclado",
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title="Anclar" arrow>
          <PushPinIcon
            fontSize="small"
            sx={{
              rotate: anchoredRows.includes(params.row.id) ? "55deg" : "0deg",
              color: anchoredRows.includes(params.row.id) ? "#407AD6" : "grey",
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleAnchorRow(params.row.id as number);
            }}
          ></PushPinIcon>
        </Tooltip>
      ),
    },
  ];

  const handleOpen = (purchase: any) => {
    setSelectedPurchase(purchase);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleAnchorRow = (rowId: number) => {
    setAnchoredRows((prevAnchoredRows) => {
      if (prevAnchoredRows.includes(rowId)) {
        return prevAnchoredRows.filter((id) => id !== rowId);
      } else {
        return [rowId, ...prevAnchoredRows];
      }
    });
  };

  const getRowId = (purchase: WorkshopPurchase) => purchase.id;

  const getRowClassName = (
    params: GridRowClassNameParams<WorkshopPurchase>
  ) => {
    const isAnchored = anchoredRows.includes(params.row.id);
    const isAdditional = params.row.is_additional;

    switch (true) {
      case isAnchored && isDarkMode:
        return "anchoredRowDark";
      case isAnchored && !isDarkMode:
        return "anchoredRow";
      case isAdditional && isDarkMode:
        return "additionalRowDark";
      case isAdditional && !isDarkMode:
        return "additionalRow";
      default:
        return "";
    }
  };

  /**
   *
   * Next function has responsability to sort rows.
   *
   * Currently we can sort just per 1 column
   *
   */
  const handleSortModelChange = (newModels: GridSortModel) => {
    if (!newModels.length) {
      return;
    }

    const isAscending = sortDirection === ascending;
    const unanchoredRows = purchases.filter(
      (p: WorkshopPurchase) => !anchoredRows.includes(p.id)
    );

    unanchoredRows.sort((a, b) => {
      const columnToSort = newModels[0].field;
      const aValue = a[columnToSort as keyof WorkshopPurchase];
      const bValue = b[columnToSort as keyof WorkshopPurchase];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAscending ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return isAscending
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });

    setSortDirection(isAscending ? descending : ascending);

    const combinedPurchases = [
      ...purchases.filter((p) => anchoredRows.includes(p.id)),
      ...unanchoredRows,
    ];

    const pagedPurchases = combinedPurchases.slice(
      page * pageSize,
      (page + 1) * pageSize
    );

    setSortedPurchases(pagedPurchases);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    if (
      newPage * pageSize >= purchases.length &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <h2>Compras</h2>
          <DataGrid
            sx={{ minHeight: "50vh" }}
            loading={loading || isFetchingNextPage}
            slotProps={{ row: { style: { cursor: "pointer" } } }}
            rows={sortedPurchases}
            columns={columns}
            onRowClick={(params) => handleOpen(params.row)}
            getRowId={getRowId}
            getRowClassName={getRowClassName}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            pagination
            paginationMode="server"
            rowCount={totalCount}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(params) => handlePageChange(params.page)}
          />
          {selectedPurchase && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <ModalDetail
                  carSummary={Car.getSummary(selectedPurchase.budget.car)}
                  purchase={selectedPurchase}
                  handleClose={handleClose}
                  onPurchaseChange={async (
                    updatedPurchase: WorkshopPurchase
                  ) => {
                    await updatePurchase(updatedPurchase.id, updatedPurchase);
                    setSelectedPurchase(updatedPurchase);
                  }}
                />
              </Box>
            </Modal>
          )}
        </Box>
      )}
    </>
  );
}
