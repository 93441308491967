import DragHandleIcon from "@mui/icons-material/DragHandle";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { WorkshopPurchasePriority } from "../../entities/WorkshopPurchasePriority.entity";

interface PriorityIcon {
  id: number;
}

export default function PriorityIcon({ id }: PriorityIcon) {
  switch (id) {
    case WorkshopPurchasePriority.SCHEDULE_ID:
      return <DragHandleIcon fontSize="small" sx={{ color: "#2563eb" }} />;
    case WorkshopPurchasePriority.URGENT_ID:
      return <KeyboardArrowUpIcon fontSize="small" sx={{ color: "#d97706" }} />;
    case WorkshopPurchasePriority.PUP_ID:
      return (
        <KeyboardDoubleArrowUpIcon fontSize="small" sx={{ color: "#E11D48" }} />
      );
    default:
      return <></>;
  }
}
