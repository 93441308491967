export class WorkshopPurchasePriority {
  static readonly SCHEDULE_ID = 1;
  static readonly URGENT_ID = 2;
  static readonly PUP_ID = 3;

  readonly id!: number;
  readonly name!: string;
  readonly active!: boolean;

  constructor(data: Partial<WorkshopPurchasePriority>) {
    Object.assign(this, data);
  }
}
