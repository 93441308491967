import { SyntheticEvent, useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FormattedNumber } from "react-intl";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import Loading from "../Common/Loading";
import CommentSection from "./CommentSection";
import { format, parseISO } from "date-fns";
import { PurchaseContext } from "../Pages/Purchase/context/PurchaseContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns: GridColDef[] = [
  { field: "user_full_name", headerName: "Usuario", flex: 0.8 },
  {
    field: "field",
    headerName: "Cambio",
    flex: 0.5,
    renderCell: (params) => {
      switch (params.value) {
        case "price":
          return "Precio";
        case "quantity":
          return "Cantidad";
        case "workshop_purchase_state_id":
        case "workshop_purchase_item_state_id":
          return "Estado";
        case "responsible_id":
          return "Responsable";
        case "description":
          return "Descripción";
        default:
          return params.value;
      }
    },
  },
  {
    field: "old_value_desc",
    headerName: "Valor Anterior",
    flex: 1,
    renderCell: (params) => {
      if (params.row.field == "price" && params.value > 0) {
        return (
          <FormattedNumber
            value={params.value}
            style="currency"
            currency="ARS"
          />
        );
      }

      return params.value;
    },
  },
  {
    field: "new_value_desc",
    headerName: "Valor Nuevo",
    flex: 1,
    renderCell: (params) => {
      if (params.row.field == "price" && params.value > 0) {
        return (
          <FormattedNumber
            value={params.value}
            style="currency"
            currency="ARS"
          />
        );
      }

      return params.value;
    },
  },
  { field: "model_description", headerName: "Item", flex: 1 },
  {
    field: "stamp",
    headerName: "Fecha de Ejecución",
    flex: 1,
    renderCell: (params) => {
      if (!params.value) {
        return "Fecha no disponible";
      }

      try {
        const date = new Date(parseISO(params.value) + "Z");
        return format(date, "dd/MM/yy HH:mm:ss");
      } catch (error) {
        console.error("Error parsing date:", error);
        return "Fecha inválida";
      }
    },
  },
];

interface ComponentProps {
  purchaseId: number;
}

export default function History({ purchaseId }: ComponentProps) {
  const service = new WorkshopPurchaseService();
  const [selectedTab, setSelectedTab] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedPurchase } = useContext(PurchaseContext);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (window.location.hash.includes("#comment-")) {
      setSelectedTab(1);
    }
    service
      .history(purchaseId)
      .then((res) => {
        const rowsWithCreation = (res || []).concat({
          id: "created_info",
          user_full_name: selectedPurchase?.createdBy.fullname || "Unknown",
          field: "Creación",
          old_value_desc: "",
          new_value_desc: "Creador de la compra",
          model_description: "",
          stamp: selectedPurchase?.created_at,
        });
        setRows(rowsWithCreation);
      })
      .finally(() => setLoading(false));
  }, [purchaseId, selectedPurchase]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: "100%", minHeight: "250px" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Historial de cambios" {...a11yProps(0)} />
        <Tab label="Comentarios" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
        {!rows.length ? (
          <p>Sin cambios</p>
        ) : (
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={rows}
            columns={columns}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <CommentSection purchaseId={purchaseId} />
      </CustomTabPanel>
    </Box>
  );
}
