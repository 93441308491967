// import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux-store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IntlProvider } from "react-intl";
import { SnackbarProvider } from "notistack";
import { CustomThemeProvider } from "./components/ThemeContext.tsx";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import App from "./App.tsx";
import ErrorBoundary from "./components/ErrorBoundary.tsx";

import "./index.css";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  // Enable next if you require it
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <CustomThemeProvider>
        <IntlProvider locale="es-AR">
          <SnackbarProvider maxSnack={3}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </SnackbarProvider>
        </IntlProvider>
      </CustomThemeProvider>
    </Provider>
  </QueryClientProvider>
  // </React.StrictMode>
);
