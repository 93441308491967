import { enqueueSnackbar } from "notistack";
import { Budget } from "../../../../entities/budget.entity";
import { BudgetService } from "../../../../services/BudgetService";

export const onDragEndBudget = async (
  result: any,
  columns: any,
  setColumns: any,
  setItems: any,
  items: Budget[]
) => {
  const budgetService = new BudgetService();

  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);

    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    };

    const previousColumns = { ...columns };
    setColumns(newColumns);

    const movedBudget = items.find(
      (budget) => budget.id === parseInt(removed.id)
    );

    if (movedBudget) {
      try {
        const updatedBudget = await budgetService.update(movedBudget.id, {
          workshop_budget_state_id: destColumn.id,
        });

        setItems((prevBudgets: Budget[]) =>
          prevBudgets.map((budget) =>
            budget.id === updatedBudget.id ? updatedBudget : budget
          )
        );
        enqueueSnackbar("Se actualizó el estado del presupuesto", {
          variant: "success",
        });
      } catch (error) {
        console.error("Error updating budget state:", error);
        setColumns(previousColumns);
        enqueueSnackbar("Error al actualizar el estado del presupuesto", {
          variant: "error",
        });
      }
    }
  }
};
