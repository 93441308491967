import { useEffect, useState } from "react";
import Loading from "./components/Common/Loading";
import MainLayout from "./components/MainLayout";
import { useAppDispatch, useAppSelector } from "./redux-store/hooks";
import { removeUser, setUser } from "./redux-store/slices/UserSlice";
import Signin from "./components/Pages/Auth/Signin";
import {
  getDocodedAuthUserFromToken,
  getToken,
  removeToken,
} from "./services/AuthService";

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userReducer.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getToken();

    if (token) {
      try {
        const decodedUser = getDocodedAuthUserFromToken(token);
        dispatch(setUser(decodedUser));
      } catch (error) {
        console.error("Token inválido o expirado:", error);
        removeToken();
        dispatch(removeUser());
      }
    } else {
      setLoading(false);
    }

    setLoading(false);
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  if (!user || !user.id) {
    return <Signin />;
  }

  return <MainLayout />;
}

export default App;
