import { CircularProgress, MenuItem } from "@mui/material";
import { GridToolbarExportContainer } from "@mui/x-data-grid";
import { useState } from "react";
import { WorkshopPurchaseItemService } from "../../../services/WorkshopPurchaseItemService";

function BackendExportMenuItem(props: any) {
  const { hideMenu } = props;
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    const workshopService = new WorkshopPurchaseItemService();
    try {
      await workshopService.downloadReport();
    } catch (error) {
      console.error("Error al descargar el archivo desde el backend:", error);
    } finally {
      setLoading(false);
      hideMenu?.();
    }
  };

  return (
    <MenuItem onClick={handleExport} disabled={loading}>
      {loading ? (
        <>
          <CircularProgress size={24} sx={{ marginRight: 1 }} />
          Descargando...
        </>
      ) : (
        "Descargar reporte en Excel"
      )}
    </MenuItem>
  );
}

export default function AppToolbarExport() {
  return (
    <GridToolbarExportContainer>
      <BackendExportMenuItem />
    </GridToolbarExportContainer>
  );
}
