import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";

import { WorkshopPurchaseService } from "../../../services/WorkshopPurchaseService";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import Loading from "../../Common/Loading";
import ModalDetail from "../../Purchase/ModalDetail";
import { Car } from "../../../entities/car.entity";

import "./Detail.css";

export default function Detail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const service = new WorkshopPurchaseService();
  const [purchase, setPurchase] = useState<undefined | WorkshopPurchase>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    service
      .findOneById(Number(id))
      .then((p) => setPurchase(p))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!purchase) {
    return <>Ups. La compra no existe</>;
  }

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalDetail
        carSummary={Car.getSummary(purchase.budget.car)}
        purchase={purchase}
        handleClose={() => navigate("/")}
        onPurchaseChange={(p: WorkshopPurchase) => setPurchase(p)}
      />
    </Modal>
  );
}
