import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const styles = {
  select: {
    backgroundColor: "#2e7d32",
    color: "white",
    textAlign: "center" as const,
  },
  rowCart: {
    width: "100%",
    padding: "6px",
  },
};

interface budgetTopPanelProps {
  budget: any;
}

export default function TopPanelBudget({ budget }: budgetTopPanelProps) {
  return (
    <>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 0.5fr", gap: "10px" }}
      >
        <Paper>
          <Box>
            <Table size="small">
              <TableBody sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "6px 6px 6px 16px",
                      }}
                      scope="row"
                    >
                      <strong>Motor:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.engine_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                      <strong>Chasis:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.chassis_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px 6px 16px" }} scope="row">
                      <strong>Color:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.color}
                    </TableCell>
                  </TableRow>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Cliente:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.company.name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Tipo de servicio:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.engine_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>Km al solicitar:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.car.usage}
                    </TableCell>
                  </TableRow>
                </Box>
              </TableBody>
            </Table>
          </Box>
        </Paper>
        <Paper>
          <Box>
            <Table>
              <TableBody>
                <Box sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell sx={{ padding: "6px" }} scope="row">
                      <strong>Responsable:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.createdBy.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px" }} scope="row">
                      <strong>Días de Validez:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.expiration_days}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", padding: "6px" }}
                      scope="row"
                    >
                      <strong>En taller:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.in_workshop != true ? "No" : "Si"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px" }} scope="row">
                      <strong>Ciudad/Localidad:</strong>
                    </TableCell>
                    <TableCell sx={styles.rowCart} scope="row">
                      {budget.location}
                    </TableCell>
                  </TableRow>
                </Box>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
