import { format } from "date-fns";

export const parseUTCDate = (value: string | Date): string => {
  let date: Date;

  if (typeof value === "string") {
    date = new Date(value + "Z");
  } else {
    date = value;
  }

  return format(date, "dd/MM/yy HH:mm:ss");
};
