import jwt_decode from "jwt-decode";

import ApiService from "./ApiService";
import { AuthUserType } from "../types/AuthUserType";
import { PermissionEnum } from "../enum/permission.enum";

export const login = async (
  username: string,
  password: string
): Promise<AuthUserType | undefined | false> => {
  try {
    const response = await ApiService.post("/api_v2/user/login", {
      username,
      password,
    });

    const user = getDocodedAuthUserFromToken(response.data.data.token);

    if (!user.permissions.includes(PermissionEnum.PGT_ACCESS)) {
      return false;
    }

    return {
      id: user.id,
      roles: user.roles,
      permissions: user.permissions,
      token: response.data.data.token,
      name: user.name,
    };
  } catch (e) {
    console.error(e);

    return undefined;
  }
};

export const logout = () => {
  throw new Error("Not implemented function");
};

export const setToken = (token: string): void => {
  localStorage.setItem("_", token);
};

export const removeToken = (): void => {
  localStorage.removeItem("_");
};

export const getToken = (): string | null => {
  return localStorage.getItem("_");
};

export const getDocodedAuthUserFromToken = (
  token: string
): { roles: string[]; permissions: string[]; id: number; name: string } => {
  const decoded = jwt_decode(token) as any;

  if (decoded && decoded.uid && decoded.roles && decoded.permissions) {
    return {
      roles: decoded.roles,
      permissions: decoded.permissions,
      id: decoded.uid as number,
      name: decoded.name,
    };
  }

  throw new Error("Token decoded structure was changed");
};
