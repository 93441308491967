import { Box, Tooltip, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { format, isBefore, isToday } from "date-fns";

interface UndatedIconProps {
  undated: string;
}

export const UndatedIcon = ({ undated }: UndatedIconProps) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.26)",
      padding: "2px 5px 2px 5px",
      borderRadius: "4px",
      minHeight: "24px",
    }}
  >
    <Typography sx={{ fontSize: "12px", color: "white" }}>{undated}</Typography>
  </Box>
);

interface TurnIconProps {
  appointment: Date | null;
}

export const TurnIcon = ({ appointment }: TurnIconProps) => {
  const currentDate = new Date();
  const turnDate = new Date(appointment + "Z");

  let backgroundColor;
  if (isBefore(turnDate, currentDate) && !isToday(turnDate)) {
    backgroundColor = "#D61432";
  } else if (isToday(turnDate)) {
    backgroundColor = "#4CAF50";
  } else {
    backgroundColor = "gray";
  }

  return (
    <Box>
      {appointment ? (
        <Tooltip arrow title="Turno">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: backgroundColor,
              padding: "2px 5px",
              borderRadius: "4px",
              minHeight: "24px",
            }}
          >
            <CalendarMonthIcon fontSize="small" sx={{ color: "white" }} />{" "}
            <Typography
              sx={{ fontSize: "12px", color: "white", marginLeft: "2px" }}
            >
              {format(turnDate, "dd/MM/yyyy")}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <UndatedIcon undated="Sin turno" />
      )}
    </Box>
  );
};

interface ArrivalDateIconProps {
  workshop_arrival_date: Date | null;
  workshop_exit_date: Date | null;
}

export const ArrivalDateIcon = ({
  workshop_exit_date,
  workshop_arrival_date,
}: ArrivalDateIconProps) => (
  <Box>
    {workshop_exit_date ? (
      <ExitDateIcon workshop_exit_date={workshop_exit_date} />
    ) : workshop_arrival_date ? (
      <Tooltip arrow title="Fecha de ingreso">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "#388bff",
            padding: "2px 5px",
            borderRadius: "4px",
            minHeight: "24px",
          }}
        >
          <CarCrashIcon fontSize="small" sx={{ color: "white" }} />
          <Typography
            sx={{ fontSize: "12px", color: "white", marginLeft: "2px" }}
          >
            {format(workshop_arrival_date, "dd/MM/yyyy")}
          </Typography>
        </Box>
      </Tooltip>
    ) : (
      <UndatedIcon undated="Sin fecha de ingreso" />
    )}
  </Box>
);

interface ExitDateIconProps {
  workshop_exit_date: Date | null;
}

export const ExitDateIcon = ({ workshop_exit_date }: ExitDateIconProps) => (
  <Box>
    <Tooltip arrow title="Fecha de egreso">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#2F3645",
          padding: "2px 5px",
          borderRadius: "4px",
          minHeight: "24px",
        }}
      >
        <TimeToLeaveIcon fontSize="small" sx={{ color: "white" }} />
        <Typography
          sx={{ fontSize: "12px", color: "white", marginLeft: "2px" }}
        >
          {workshop_exit_date && format(workshop_exit_date, "dd/MM/yyyy")}
        </Typography>
      </Box>
    </Tooltip>
  </Box>
);
