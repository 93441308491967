import { PurchaseProvider } from "../../Purchase/context/PurchaseProvider";
import { BudgetProvider } from "./BudgetProvider";

interface CombinedProviderProps {
  children: JSX.Element | JSX.Element[];
}

const CombinedProvider = ({ children }: CombinedProviderProps) => (
  <PurchaseProvider>
    <BudgetProvider>{children}</BudgetProvider>
  </PurchaseProvider>
);

export default CombinedProvider;
