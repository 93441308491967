import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import BarcodeScanner from "./BarcodeScanner";

interface SearchBarProps {
  products: any[];
  onFilteredProducts: (filteredProducts: any[]) => void;
}

export default function SearchBar({
  products,
  onFilteredProducts,
}: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [scannerOpen, setScannerOpen] = useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    const filteredProducts = getFilteredProducts(event.target.value, products);
    onFilteredProducts(filteredProducts);
  };

  const handleScan = (barcode: string) => {
    setSearchTerm(barcode);
    const filteredProducts = getFilteredProducts(barcode, products);
    onFilteredProducts(filteredProducts);
    setScannerOpen(false);
  };

  const getFilteredProducts = (searchTerm: string, products: any[]) => {
    return products.filter((product) => {
      const searchInFields = [
        product.id,
        product.title,
        product.category,
        product.description,
        product.rating.count,
        product.barcode,
      ];

      return searchInFields.some((field) =>
        field
          ? field.toString().toLowerCase().includes(searchTerm.toLowerCase())
          : false
      );
    });
  };

  return (
    <>
      <TextField
        variant="outlined"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 2 }}
        size="small"
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton onClick={() => setScannerOpen(true)}>
                  <CropFreeIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <Dialog
        open={scannerOpen}
        onClose={() => setScannerOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Escanear Código de Barras</DialogTitle>
        <DialogContent>
          <BarcodeScanner onDetected={handleScan} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setScannerOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
