import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../entities/user.entity";

const initialState: { user: User } = {
  user: new User({}),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = new User({}); // TODO: IS NOT A SERIALIZE OBJECT ¡¡¡ CHANGE !!!
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
