export class WorkshopPurchaseItemState {
  static readonly QUOTE_ID = 1;
  static readonly QUOTED_ID = 2;
  static readonly REJECTED_ID = 3;
  static readonly BUY_ID = 4;
  static readonly LOGISTIC_TRANSIT_ID = 5;
  static readonly MISSING_FROM_FACTORY_ID = 6;
  static readonly IN_STOCK_ID = 7;
  static readonly DELIVERED_REPAIRED_SAMPLE_ID = 8;
  static readonly IN_OUTSOURCED_WORKSHOP_ID = 9;
  static readonly DELIVERED_ID = 10;
  static readonly IN_VERIFICATION_ID = 11;
  static readonly FINISHED_ID = 12;
  static readonly REPLACE_PART_ID = 13;
  static readonly RETURN_ID = 14;

  static readonly DELIVERED_REPAIRED_SAMPLE_LABEL = "Muestra Entregada";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly REJECTED_LABEL = "Rechazado";
  static readonly QUOTE_LABEL = "Cotizar";
  static readonly QUOTED_LABEL = "Cotizado";
  static readonly BUY_LABEL = "Comprar";
  static readonly LOGISTIC_TRANSIT_LABEL = "En tránsito logística";
  static readonly MISSING_FROM_FACTORY_LABEL = "Faltante de fábrica";
  static readonly IN_STOCK_LABEL = "En stock";
  static readonly IN_OUTSOURCED_WORKSHOP_LABEL = "En taller terciarizado";
  static readonly DELIVERED_LABEL = "Entregado";
  static readonly IN_VERIFICATION_LABEL = "En verificación de repuestos";
  static readonly REPLACE_PART_LABEL = "Cambiar repuesto";
  static readonly RETURN_LABEL = "Cambiar repuesto";

  readonly id!: number;
  readonly name!: string;
  readonly active!: boolean;

  constructor(data: Partial<WorkshopPurchaseItemState>) {
    Object.assign(this, data);
  }

  static getIdAndLabels() {
    return [
      {
        id: WorkshopPurchaseItemState.QUOTE_ID,
        label: WorkshopPurchaseItemState.QUOTE_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.QUOTED_ID,
        label: WorkshopPurchaseItemState.QUOTED_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.REJECTED_ID,
        label: WorkshopPurchaseItemState.REJECTED_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.BUY_ID,
        label: WorkshopPurchaseItemState.BUY_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.LOGISTIC_TRANSIT_ID,
        label: WorkshopPurchaseItemState.LOGISTIC_TRANSIT_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.MISSING_FROM_FACTORY_ID,
        label: WorkshopPurchaseItemState.MISSING_FROM_FACTORY_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.IN_STOCK_ID,
        label: WorkshopPurchaseItemState.IN_STOCK_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.DELIVERED_REPAIRED_SAMPLE_ID,
        label: WorkshopPurchaseItemState.DELIVERED_REPAIRED_SAMPLE_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.IN_OUTSOURCED_WORKSHOP_ID,
        label: WorkshopPurchaseItemState.IN_OUTSOURCED_WORKSHOP_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.DELIVERED_ID,
        label: WorkshopPurchaseItemState.DELIVERED_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.IN_VERIFICATION_ID,
        label: WorkshopPurchaseItemState.IN_VERIFICATION_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.FINISHED_ID,
        label: WorkshopPurchaseItemState.FINISHED_LABEL,
      },
      {
        id: WorkshopPurchaseItemState.REPLACE_PART_ID,
        label: WorkshopPurchaseItemState.REPLACE_PART_LABEL,
      },
    ];
  }
}
