import {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
  ReactNode,
} from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SearchIcon from "@mui/icons-material/Search";
import { useDarkMode } from "../../ThemeContext";
import { ColumnsBudget } from "./BudgetKanban/TypeKanbanBudget";
import { ColumnsPurchase } from "./PurchaseKanban/TypeKanbanPurchase";

interface KanbanProps<T> {
  onDragEndHandler: Function;
  renderItems: Function;
  setSelectedItem: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  items: T[];
  setItems:
    | Dispatch<SetStateAction<T[]>>
    | ((updatedItems: T[]) => Promise<void>);
  renderModal: () => ReactNode;
  loading: boolean;
  getColumnBackgroundColor: any;
  columns: ColumnsBudget | ColumnsPurchase;
  setColumns:
    | Dispatch<SetStateAction<ColumnsBudget>>
    | Dispatch<SetStateAction<ColumnsPurchase>>;
}

export default function Kanban<T>({
  onDragEndHandler,
  renderItems,
  setSelectedItem,
  open,
  setOpen,
  items,
  setItems,
  renderModal,
  loading,
  getColumnBackgroundColor,
  columns,
  setColumns,
}: KanbanProps<T>) {
  const [showShadow, setShowShadow] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isDarkMode } = useDarkMode();

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setShowShadow(scrollContainerRef.current.scrollLeft > 0);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleOpen = (itemId: string) => {
    const selectedItemCard = items.find(
      (item: any) => item.id.toString() === itemId
    );

    if (open == false) {
      setSelectedItem(selectedItemCard);
      setOpen(true);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getFilteredTasks = (tasks: any[]) => {
    return tasks.filter((task) => {
      const searchInFields = [
        task.carId,
        task.company,
        task.id,
        task.brandCar,
        task.modelCar,
        task.tags,
        ...(task.tags ? task.tags.map((tag: any) => tag.title) : []),
        task.name,
        task.lastName,
        task.priorityName,
        task.workshopName,
      ];

      return searchInFields.some((field) =>
        field
          ? field.toString().toLowerCase().includes(searchTerm.toLowerCase())
          : false
      );
    });
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <TextField
            variant="outlined"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2 }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box
            sx={{
              transition: "all 700ms",
              overflow: "Hidden",
              ...(showShadow && {
                boxShadow: "-15px 0px 20px -20px rgba(0,0,0,0.45)",
              }),
            }}
          >
            <DragDropContext
              onDragEnd={(result: any) => {
                onDragEndHandler(result, columns, setColumns, setItems, items);
              }}
            >
              {renderModal()}
              <Box
                ref={scrollContainerRef}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  overflow: "auto",
                  maxHeight: "80vh",
                }}
              >
                {Object.entries(columns).map(([columnId, column]: any) => {
                  const filteredTasks = getFilteredTasks(column.items);

                  return (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px",
                        background: "red",
                        borderRadius: "10px",
                        margin: "0px 16px 10px 0px",
                        backgroundColor: getColumnBackgroundColor(column.id),
                      }}
                      key={columnId}
                    >
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided: any) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              minWidth: { xs: "250px", md: "290px" },
                              gap: 3,
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "5px",
                                width: "100%",
                                backgroundColor: isDarkMode
                                  ? "#2E363E"
                                  : "white",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                fontWeight: "medium",
                              }}
                            >
                              <Box>{column.name}</Box>
                              <Box
                                sx={{
                                  marginLeft: "5px",
                                  fontSize: ".8rem",
                                }}
                              >
                                {searchTerm
                                  ? `${filteredTasks.length} de ${column.items.length}`
                                  : `${column.items.length}`}
                              </Box>
                            </Box>
                            {filteredTasks.map((task: any, index: number) => (
                              <Draggable
                                key={task.id.toString()}
                                draggableId={task.id.toString()}
                                index={index}
                              >
                                {(provided: any) => (
                                  <Box>
                                    {renderItems(provided, task, () =>
                                      handleOpen(task.id.toString())
                                    )}
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </Box>
                  );
                })}
              </Box>
            </DragDropContext>
          </Box>
        </Box>
      )}
    </>
  );
}
