import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../redux-store/hooks";
import AppLink from "./Common/AppLink";
import { removeToken } from "../services/AuthService";
import { removeUser } from "../redux-store/slices/UserSlice";
import { useAppSelector } from "../redux-store/hooks";
import { PermissionEnum } from "../enum/permission.enum";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DescriptionIcon from "@mui/icons-material/Description";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/Inventory";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { RoutePath } from "./AppRoutes";

interface PropMenu {
  open: boolean;
}

export default function LayoutMenu({ open }: PropMenu) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector((state) => state.userReducer.user);

  const handleLogout = () => {
    removeToken();
    dispatch(removeUser());
  };

  const getIconSize = () => {
    if (open) {
      return "medium";
    } else {
      return "small";
    }
  };

  const style = {
    borderRadius: "7px",
    marginBottom: 1,
  };

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        p: 1,
      }}
      component="nav"
    >
      {user.permissions.includes(PermissionEnum.WORKSHOP_PURCHASE_INDEX) && (
        <AppLink to="/">
          <ListItemButton
            selected={location.pathname == RoutePath.INDEX}
            sx={style}
          >
            <ListItemIcon>
              <ShoppingCartIcon fontSize={getIconSize()} />
            </ListItemIcon>
            <ListItemText primary="Compras" />
          </ListItemButton>
        </AppLink>
      )}

      {user.permissions.includes(PermissionEnum.BUDGET_WORKSHOP_INDEX) && (
        <AppLink to={RoutePath.BUDGET}>
          <ListItemButton
            selected={location.pathname === RoutePath.BUDGET}
            sx={style}
          >
            <ListItemIcon>
              <DescriptionIcon fontSize={getIconSize()} />
            </ListItemIcon>
            <ListItemText primary="Presupuestos" />
          </ListItemButton>
        </AppLink>
      )}

      <AppLink to={RoutePath.CALENDAR}>
        <ListItemButton
          selected={location.pathname === RoutePath.CALENDAR}
          sx={style}
        >
          <ListItemIcon>
            <CalendarMonthIcon fontSize={getIconSize()} />
          </ListItemIcon>
          <ListItemText primary="Calendario" />
        </ListItemButton>
      </AppLink>

      <AppLink to={RoutePath.REPORT}>
        <ListItemButton
          selected={location.pathname === RoutePath.REPORT}
          sx={style}
        >
          <ListItemIcon>
            <AssignmentIcon fontSize={getIconSize()} />
          </ListItemIcon>
          <ListItemText primary="Reporte" />
        </ListItemButton>
      </AppLink>

      <AppLink to={RoutePath.STOCK}>
        <ListItemButton
          selected={location.pathname === RoutePath.STOCK}
          sx={style}
        >
          <ListItemIcon>
            <InventoryIcon fontSize={getIconSize()} />
          </ListItemIcon>
          <ListItemText primary="Stock" />
        </ListItemButton>
      </AppLink>

      <Link href="#" underline="none" color="inherit">
        <ListItemButton onClick={handleLogout} sx={style}>
          <ListItemIcon>
            <ExitToAppIcon fontSize={getIconSize()} />
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </ListItemButton>
      </Link>
    </List>
  );
}
