import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AssignmentUser({}) {
  const [personName, setPersonName] = useState<string[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    const selectedNames = typeof value === "string" ? value.split(",") : value;
    setPersonName(selectedNames);

    const newRows = selectedNames.map((name, index) => ({
      id: index + 1,
      name: name,
    }));
    setRows(newRows);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Mecánico asignado", width: 150 },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredNames = rows.filter((row) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = () => {
    setPersonName([]);
    setRows([]);
  };

  const handleRemove = (nameToRemove: string) => {
    const updatedNames = personName.filter((name) => name !== nameToRemove);
    setPersonName(updatedNames);

    const newRows = updatedNames.map((name, index) => ({
      id: index + 1,
      name: name,
    }));
    setRows(newRows);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridTemplateRows: "auto auto auto",
        gridTemplateAreas: `"select search" "counter counter" "chip chip"  "tab tab" `,
        gap: personName.length > 0 ? 2 : 1,
        marginTop: "15px",
      }}
    >
      <Box sx={{ gridArea: "select" }}>
        <FormControl size="small" sx={{ width: 300 }}>
          <InputLabel size="small" id="demo-multiple-chip-label">
            Asignar mecanico
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="Asignar mecanico" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextField
        id="outlined-basic"
        label="Buscar"
        variant="outlined"
        type="search"
        size="small"
        sx={{ gridArea: "search" }}
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {personName.length > 0 && (
        <>
          <Stack direction="row" spacing={1} sx={{ gridArea: "counter" }}>
            <Box sx={{ gridArea: "counter", typography: "subtitle2" }}>
              {personName.length}
            </Box>
            <Box sx={{ typography: "body2" }}>asignaciones</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ gridArea: "chip", display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                border: "dashed 1px rgb(145 158 171 / 20%)",
                p: 0.5,
                borderRadius: "5px",
                minWidth: 300,
                minHeight: 34,
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {personName.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => handleRemove(value)}
                    size="small"
                  />
                ))}
              </Box>
            </Box>
            <Button
              onClick={handleDelete}
              size="small"
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ border: 0, "&:hover": { border: 0 } }}
            >
              Borrar
            </Button>
          </Stack>
        </>
      )}
      <Box sx={{ minHeight: 300, width: "100%", gridArea: "tab" }}>
        <DataGrid
          rows={filteredNames}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </Box>
    </Box>
  );
}
