import { useEffect, useState, Fragment } from "react";
import errorImage from "../img/500.jpg";

const ErrorBoundary = ({ children }: { children: any }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleOnError = (event: Event) => {
      console.error("Error caught by Error Boundary:", event);
      setHasError(true);
    };

    window.addEventListener("error", handleOnError);

    return () => {
      window.removeEventListener("error", handleOnError);
    };
  }, []);

  if (hasError) {
    return (
      <section className="center-text">
        <h1>
          ¡Ups! Algo salió mal. Por favor, recarga la página o intenta más
          tarde.
        </h1>
        <p>
          <a href="/">Volver al Inicio</a>
        </p>
        <img src={errorImage} width="30%" />
      </section>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default ErrorBoundary;
