import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { useDarkMode } from "./ThemeContext";
import { Box, Switch, Tooltip } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";

export default function ToggleTheme() {
  const { toggleTheme, isDarkMode } = useDarkMode();

  return (
    <Box>
      <Tooltip title={isDarkMode ? "Modo claro" : "Modo oscuro"}>
        <Switch
          checked={isDarkMode}
          onChange={toggleTheme}
          inputProps={{ "aria-label": "Dark mode" }}
          icon={<WbSunnyIcon fontSize="small" />}
          checkedIcon={<CircleIcon fontSize="small" />}
        />
      </Tooltip>
    </Box>
  );
}
