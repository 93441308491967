export class BudgetState {
  readonly id!: number;
  readonly name!: string;
  readonly client_facing_name!: string;

  static readonly QUOTE_AT_WORKSHOP_ID = 1;
  static readonly LAX_VERIFICATION_ID = 2;
  static readonly BUDGETED_ID = 3;
  static readonly APPROVED_BY_CLIENT_ID = 4;
  static readonly APPROVED_BY_LAX_ID = 5;
  static readonly EXPIRED_ID = 6;
  static readonly REJECTED_BY_CLIENT_ID = 7;
  static readonly CANCELLED_BY_LAX_ID = 8;
  static readonly IN_ACTION_ID = 9;
  static readonly REQUESTED_BY_CLIENT_ID = 10;
  static readonly FINISHED_ID = 11;
  static readonly RECOSTE_ID = 12;
  static readonly RECOSTE_AT_WORKSHOP_ID = 13;
  static readonly QUOTE_ADDITIONAL_ID = 14;
  static readonly ADDITIONALS_QUOTED_ID = 15;
  static readonly REJECTED_BY_WORKSHOP_ID = 16;

  static readonly QUOTE_AT_WORKSHOP_LABEL = "Cotizar en taller";
  static readonly LAX_VERIFICATION_LABEL = "Verificación LAX";
  static readonly BUDGETED_LABEL = "Presupuestado";
  static readonly APPROVED_BY_CLIENT_LABEL = "Aprobado por cliente";
  static readonly APPROVED_BY_LAX_LABEL = "Aprobado por lax";
  static readonly EXPIRED_LABEL = "Vencido";
  static readonly REJECTED_BY_CLIENT_LABEL = "Rechazado por cliente";
  static readonly CANCELLED_BY_LAX_LABEL = "Cancelado por lax";
  static readonly IN_ACTION_LABEL = "En ejecución";
  static readonly REQUESTED_BY_CLIENT_LABEL = "Solicitado por cliente";
  static readonly FINISHED_LABEL = "Finalizado";
  static readonly RECOSTE_LABEL = "Recotizar";
  static readonly RECOSTE_AT_WORKSHOP_LABEL = "Recotizar en taller";
  static readonly QUOTE_ADDITIONAL_LABEL = "Cotizar adicionales";
  static readonly ADDITIONALS_QUOTED_LABEL = "Adicionales presupuestado";
  static readonly REJECTED_BY_WORKSHOP_LABEL = "Rechazado por taller";

  static getIdByName(name: string): number | undefined {
    switch (name) {
      case BudgetState.QUOTE_AT_WORKSHOP_LABEL:
        return BudgetState.QUOTE_AT_WORKSHOP_ID;
      case BudgetState.LAX_VERIFICATION_LABEL:
        return BudgetState.LAX_VERIFICATION_ID;
      case BudgetState.BUDGETED_LABEL:
        return BudgetState.BUDGETED_ID;
      case BudgetState.APPROVED_BY_CLIENT_LABEL:
        return BudgetState.APPROVED_BY_CLIENT_ID;
      case BudgetState.APPROVED_BY_LAX_LABEL:
        return BudgetState.APPROVED_BY_LAX_ID;
      case BudgetState.EXPIRED_LABEL:
        return BudgetState.EXPIRED_ID;
      case BudgetState.REJECTED_BY_CLIENT_LABEL:
        return BudgetState.REJECTED_BY_CLIENT_ID;
      case BudgetState.CANCELLED_BY_LAX_LABEL:
        return BudgetState.CANCELLED_BY_LAX_ID;
      case BudgetState.IN_ACTION_LABEL:
        return BudgetState.IN_ACTION_ID;
      case BudgetState.REQUESTED_BY_CLIENT_LABEL:
        return BudgetState.REQUESTED_BY_CLIENT_ID;
      case BudgetState.FINISHED_LABEL:
        return BudgetState.FINISHED_ID;
      case BudgetState.RECOSTE_LABEL:
        return BudgetState.RECOSTE_ID;
      case BudgetState.RECOSTE_AT_WORKSHOP_LABEL:
        return BudgetState.RECOSTE_AT_WORKSHOP_ID;
      case BudgetState.QUOTE_ADDITIONAL_LABEL:
        return BudgetState.QUOTE_ADDITIONAL_ID;
      case BudgetState.ADDITIONALS_QUOTED_LABEL:
        return BudgetState.ADDITIONALS_QUOTED_ID;
      case BudgetState.REJECTED_BY_WORKSHOP_LABEL:
        return BudgetState.REJECTED_BY_WORKSHOP_ID;
      default:
        return undefined;
    }
  }

  constructor(data: Partial<BudgetState>) {
    Object.assign(this, data);
  }
}
