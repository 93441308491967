import { WorkshopPurchaseItemState } from "../entities/workshop-purchase-item-state.entity";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import ApiService from "./ApiService";

type Params = {};
export type NextState = {
  state_id: number;
  next_states: WorkshopPurchaseItemState[];
};

export class WorkshopPurchaseItemStateService
  implements BaseApplicationService<WorkshopPurchaseItemState, Params>
{
  findOneById(_id: number): Promise<WorkshopPurchaseItemState> {
    throw new Error("Method not implemented.");
  }
  findOne(_params: Params): Promise<WorkshopPurchaseItemState> {
    throw new Error("Method not implemented.");
  }
  async find(_params?: Params): Promise<WorkshopPurchaseItemState[]> {
    const response = await ApiService.get(
      "/api_v2/workshop-purchase-item-state"
    );

    return (
      response?.data.data.map(
        (state: WorkshopPurchaseItemState) =>
          new WorkshopPurchaseItemState(state)
      ) || []
    );
  }
  async getNextStates(): Promise<NextState[]> {
    const response = await ApiService.get(
      "/api_v2/workshop-purchase-item-state/next-states"
    );

    return response?.data.data || [];
  }
}
