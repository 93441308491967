import React, { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import "../Purchase/Index.css";
import BudgetList from "./BudgetList";
import { BudgetContext } from "./contexBudget/BudgetContext";
import KanbanBoard from "../KanbanBoard/KanbanBoard";
import { onDragEndBudget } from "../KanbanBoard/BudgetKanban/onDragEndBudget";
import { generateColumnsFromBudgets } from "../KanbanBoard/BudgetKanban/ColumnsBudgetKanban";
import BudgetItemsKanban from "../KanbanBoard/BudgetKanban/BudgetItemsKanban";
import { PurchaseContext } from "../Purchase/context/PurchaseContext";
import { WorkshopBudgetState } from "../../../entities/workshop-budget-state";
import BudgetModal from "./BudgetModal";
import { Budget } from "../../../entities/budget.entity";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BudgetIndex() {
  const {
    open,
    setOpen,
    activeBudgets,
    updateBudget,
    loading,
    setSelectedBudget,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useContext(BudgetContext);

  const handleSetItems = async (updatedItems: Budget[]) => {
    for (const budget of updatedItems) {
      await updateBudget(budget.id, {
        workshop_budget_state_id: budget.workshop_budget_state_id,
      });
    }
  };

  const { purchases } = useContext(PurchaseContext);

  const [value, setValue] = useState(0);
  const [columns, setColumns] = useState(
    generateColumnsFromBudgets(activeBudgets, purchases)
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getColumnBackgroundColor = (columnId: number) => {
    switch (columnId) {
      case WorkshopBudgetState.PENDING_ID:
      case WorkshopBudgetState.APPOINTMENT_ID:
      case WorkshopBudgetState.ADMITTED_ID:
      case WorkshopBudgetState.DIAGNOSIS_ID:
      case WorkshopBudgetState.FINISHED_ID:
      case WorkshopBudgetState.REPAIR_ID:
      case WorkshopBudgetState.PURCHASE_REQUESTED_ID:
      case WorkshopBudgetState.DELIVERED_ID:
        return "rgba(170, 170, 170, 0.1)";
      default:
        return "white";
    }
  };

  useEffect(() => {
    setColumns(generateColumnsFromBudgets(activeBudgets, purchases));
  }, [
    activeBudgets,
    purchases,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  ]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Board" {...a11yProps(1)} />
            <Tab label="Backlog" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={1}>
          <BudgetList />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={0}>
          <KanbanBoard<Budget>
            onDragEndHandler={onDragEndBudget}
            renderItems={(provided: any, task: any, handleOpen: any) => (
              <BudgetItemsKanban
                provided={provided}
                task={task}
                handleOpen={handleOpen}
              />
            )}
            setSelectedItem={setSelectedBudget}
            open={open}
            setOpen={setOpen}
            items={activeBudgets}
            setItems={handleSetItems}
            renderModal={() => <BudgetModal />}
            loading={loading}
            getColumnBackgroundColor={getColumnBackgroundColor}
            columns={columns}
            setColumns={setColumns}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
}
