import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "./LAX_LOGO.png";
import { Car } from "../../../entities/car.entity";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";

interface Props {
  purchase: WorkshopPurchase;
}

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
  },

  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    border: "1px solid #fcdadc",
    marginBottom: "3px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
  },

  logo: {
    maxWidth: "70px",
    maxHeight: "70px",
  },

  title: {
    fontSize: 18,
    marginBottom: 10,
    color: "#000",
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
  },
  textTitelTable: {
    fontSize: 10,
    marginBottom: 5,
  },

  table: {
    width: "auto",
    borderStyle: "solid",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    overflow: "hidden",
    border: "1px solid #fcdadc",
  },

  tableDetail: {
    width: "auto",
    borderStyle: "solid",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    overflow: "hidden",
    border: "1px solid #fcdadc",
  },

  tableRow: {
    flexDirection: "row",
    backgroundColor: "rgb(251, 251, 251)",
    borderRadius: "6px",
  },

  tableRowDetail: {
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: "6px",
  },

  tableColDetailColOne: {
    padding: "5px",
    width: "60%%",
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
  },

  tableColDetailColTwe: {
    padding: "5px",
    width: "40%",
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
  },

  tableColTitel: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 0.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "rgba(214, 20, 50, 0.08)",
  },

  tableColNoBorderRightTitel: {
    width: "20%",
    borderStyle: "solid",
    backgroundColor: "rgba(214, 20, 50, 0.08)",
    borderWidth: 0.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
  },

  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColNoBorderBottom: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 0.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },

  tableColNoBorderRight: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
  },

  tableCell: {
    margin: 5,
    fontSize: 10,
  },

  titelTable: {
    margin: 5,
    fontSize: 10,
  },

  detailTitel: {
    padding: "5px 5px 0px 5px",
    textAlign: "center",
    width: "100%",
    backgroundColor: "rgba(214, 20, 50, 0.08)",
    border: "1px solid #fcdadc",
    marginBottom: "3px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
  },

  comment: {
    minHeight: "50px",
    padding: "5px",
    border: "1px solid #fcdadc",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    marginBottom: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: "8px",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const formatQuantity = (quantity: number) => {
  return new Intl.NumberFormat("es-AR", {
    maximumFractionDigits: 0,
  }).format(quantity);
};

const PDFPurchase: React.FC<Props> = ({ purchase }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.containerHeader}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={styles.logo}>
            <Image src={logo} />
          </View>

          <View>
            <View>
              <Text style={styles.text}>Mantenimiento de flota</Text>
            </View>
            <View>
              <Text style={styles.text}>
                Av. Gral. Gelly y Obes 2233 - C1119 CABA
              </Text>
              <Text style={styles.text}>TLF: 11 52783516</Text>
              <Text style={styles.text}>
                Mail: operaciones@laxargentina.com
              </Text>
            </View>
          </View>
        </View>
        <View style={{ padding: "5px" }}>
          <Text style={styles.title}>Cotización de Servicios</Text>
          <Text style={styles.text}>Presupuesto N°: {purchase.budget.id}</Text>
          <Text style={styles.text}>Compra N°: {purchase.id}</Text>
          <Text style={styles.text}>Documento no valido como factura.</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.tableDetail}>
          <View style={styles.tableRowDetail}>
            <View style={styles.tableColDetailColOne}>
              <View>
                <Text style={styles.textTitelTable}>Unidad: </Text>
                <Text style={styles.textTitelTable}>Motor: </Text>
                <Text style={styles.textTitelTable}>Chasis: </Text>
                <Text style={styles.textTitelTable}>Color: </Text>
                <Text style={styles.textTitelTable}>Cliente: </Text>
              </View>
              <View>
                <Text style={styles.text}>
                  {Car.getSummary(purchase.budget.car)}
                </Text>
                <Text style={styles.text}>
                  {purchase.budget.car.engine_number}
                </Text>
                <Text style={styles.text}>
                  {purchase.budget.car.chassis_number}
                </Text>
                <Text style={styles.text}>{purchase.budget.car.color}</Text>
                <Text style={styles.text}>{purchase.budget.company.name}</Text>
              </View>
            </View>
            <View style={styles.tableColDetailColTwe}>
              <View>
                <Text style={styles.textTitelTable}>Tipo de Servicio: </Text>
                <Text style={styles.textTitelTable}>Km al solicitar: </Text>
                <Text style={styles.textTitelTable}>Responsable: </Text>
                <Text style={styles.textTitelTable}>Días de Validez: </Text>
                <Text style={styles.textTitelTable}>En taller: </Text>
              </View>
              <View>
                <Text style={styles.text}>
                  {purchase.budget.car.car_type_id
                    ? purchase.budget.car.car_type_id
                    : "Sin tipo de servicio"}
                </Text>
                <Text style={styles.text}>{purchase.budget.usage}</Text>
                <Text style={styles.text}>
                  {purchase.budget.createdBy.fullname}
                </Text>
                <Text style={styles.text}>
                  {purchase.budget.expiration_days}
                </Text>
                <Text style={styles.text}>
                  {purchase.budget.in_workshop ? "Sí" : "No"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.detailTitel}>
        <Text style={styles.text}>Descripción</Text>
      </View>
      <View style={styles.comment}>
        <Text style={styles.text}>{purchase.budget.description}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>Items a comprar</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitel}>
              <Text style={styles.titelTable}>Item</Text>
            </View>
            <View style={styles.tableColTitel}>
              <Text style={styles.titelTable}>Marca</Text>
            </View>
            <View style={styles.tableColTitel}>
              <Text style={styles.titelTable}>Descripción</Text>
            </View>
            <View style={styles.tableColTitel}>
              <Text style={styles.titelTable}>Cantidad</Text>
            </View>
            <View style={[styles.tableCol, styles.tableColNoBorderRightTitel]}>
              <Text style={styles.titelTable}>Estado</Text>
            </View>
          </View>
          {purchase.workshopPurchaseItems.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View
                style={
                  index === purchase.workshopPurchaseItems.length - 1
                    ? styles.tableColNoBorderBottom
                    : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>
                  {item.budgetItem.repairSubitem.name}
                </Text>
              </View>
              <View
                style={
                  index === purchase.workshopPurchaseItems.length - 1
                    ? styles.tableColNoBorderBottom
                    : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>{item.brand}</Text>
              </View>
              <View
                style={
                  index === purchase.workshopPurchaseItems.length - 1
                    ? styles.tableColNoBorderBottom
                    : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>{item.description}</Text>
              </View>
              <View
                style={
                  index === purchase.workshopPurchaseItems.length - 1
                    ? styles.tableColNoBorderBottom
                    : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>
                  {formatQuantity(item.quantity)}
                </Text>
              </View>
              <View
                style={
                  index === purchase.workshopPurchaseItems.length - 1
                    ? [
                        styles.tableColNoBorderBottom,
                        styles.tableColNoBorderRight,
                      ]
                    : styles.tableColNoBorderRight
                }
              >
                <Text style={styles.tableCell}>
                  {item.workshopPurchaseItemState.name}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default PDFPurchase;
