import ApiService from "./ApiService";

export class UserNotificationService {
  async getNotViewedCount(): Promise<number | undefined> {
    const response = await ApiService.get(
      "/api_v2/user-notification/not-viewed"
    );

    return response?.data.data.total;
  }
  async getNotifications(page: number): Promise<Array<any>> {
    const response = await ApiService.get(
      `/api_v2/user-notification?page=${page}`
    );

    return response?.data.data;
  }

  async setAsViewed(id: number) {
    return ApiService.post(`/api_v2/user-notification/mark-as-viewed?id=${id}`);
  }
}
