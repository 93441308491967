import axios, { AxiosError, HttpStatusCode } from "axios";

import { getToken, removeToken } from "./AuthService";

const ApiService = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
});

ApiService.interceptors.request.use((config: any) => {
  return {
    ...config,
    headers: { Authorization: `Bearer ${getToken()}` },
  };
});

ApiService.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error.code === AxiosError.ERR_NETWORK) {
      alert("No se pudo conectar con el servidor.");
      return;
    }

    if (error.response.status === HttpStatusCode.Unauthorized) {
      removeToken();
      location.href = "/";
    }
    if (error.response.status === HttpStatusCode.Forbidden) {
      alert("No tienes permiso a este recurso.");
    }
    return Promise.reject(error);
  }
);

export async function fetchPaginatedData<T>(
  endpoint: string,
  page = 1,
  pageSize = 20,
  filters?: any
): Promise<{ data: T[]; totalCount: number } | Blob> {
  try {
    const response = await ApiService.get(endpoint, {
      params: {
        page,
        pageSize,
        ...filters,
      },
    });

    const data = response?.data.data.map((item: T) => item) || [];
    const totalCount = parseInt(
      response.headers["x-pagination-total-count"],
      10
    );
    return { data, totalCount };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export default ApiService;
