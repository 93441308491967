import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Paper,
  TableContainer,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { WorkshopPurchaseItemService } from "../../services/WorkshopPurchaseItemService";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import {
  NextState,
  WorkshopPurchaseItemStateService,
} from "../../services/WorkshopPurchaseItemStateService";
import { FieldError } from "../../types/FieldError";
import { FormattedNumber } from "react-intl";
import { WorkshopPurchaseState } from "../../entities/workshop-purchase-state.entity";
import { WorkshopPurchaseItemState } from "../../entities/workshop-purchase-item-state.entity";
import { RepairSubitem } from "../../entities/repair-subitem.entity";
import { useSnackbar } from "notistack";
import ScrollHint from "scroll-hint";
import "scroll-hint/css/scroll-hint.css";
import PriceInput from "../WorkshopPurchaseItem/PriceInput";
import { WorkshopPurchase } from "../../entities/workshop-purchase.entity";
import { useLocation } from "react-router-dom";
import { RoutePath } from "../AppRoutes";
import { useDarkMode } from "../ThemeContext";

interface WorkshopPurchaseItemList {
  isEditionDisabled?: boolean;
  onItemChange: Function;
  onPurchaseChange: Function;
  purchase: WorkshopPurchase;
}

export default function WorkshopPurchaseItemList({
  isEditionDisabled = false,
  onItemChange,
  onPurchaseChange,
  purchase,
}: WorkshopPurchaseItemList) {
  const itemService = new WorkshopPurchaseItemService();
  const purchaseService = new WorkshopPurchaseService();
  const stateService = new WorkshopPurchaseItemStateService();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const isBudgetView = location.pathname === RoutePath.BUDGET;

  const [latestItemPrices, setLatestItemPrices] = useState<any[]>([]);
  const [nextStates, setNextStates] = useState<NextState[]>([]);
  const [errors, setErrors] = useState<FieldError[]>();
  const { isDarkMode } = useDarkMode();

  const handleStateChange = (
    event: SelectChangeEvent<number>,
    index: number
  ) => {
    const oldItem = { ...purchase.workshopPurchaseItems[index] };
    itemService
      .update(purchase.workshopPurchaseItems[index].id, {
        workshop_purchase_item_state_id: Number(event.target.value),
      })
      .then((item) => {
        if ("errors" in item) {
          setErrors(item.errors);
          onItemChange(oldItem);
        } else {
          let requiredItemStateId: number | undefined;
          let purchaseStateIdPair: number | undefined;
          let purchaseStateTransitionLabel: string = "";

          switch (item.workshopPurchaseItemState.id) {
            case WorkshopPurchaseItemState.QUOTED_ID:
              requiredItemStateId = WorkshopPurchaseItemState.QUOTED_ID;
              purchaseStateIdPair = WorkshopPurchaseState.QUOTED_ID;
              purchaseStateTransitionLabel = WorkshopPurchaseState.QUOTED_LABEL;
              break;
            case WorkshopPurchaseItemState.DELIVERED_ID:
              requiredItemStateId = WorkshopPurchaseItemState.DELIVERED_ID;
              purchaseStateIdPair = WorkshopPurchaseState.DELIVERED_ID;
              purchaseStateTransitionLabel =
                WorkshopPurchaseState.DELIVERED_LABEL;
              break;
          }

          if (
            requiredItemStateId &&
            purchaseStateIdPair &&
            purchase.workshopPurchaseItems.filter(
              (i) =>
                i.workshopPurchaseItemState.id == requiredItemStateId &&
                i.id != item.id
            ).length ==
              purchase.workshopPurchaseItems.length - 1
          ) {
            purchaseService
              .update(purchase.id, {
                workshop_purchase_state_id: purchaseStateIdPair,
              })
              .then((p) => {
                onPurchaseChange(p);
                enqueueSnackbar(
                  "Se ha actualizado el estado de la compra a " +
                    purchaseStateTransitionLabel
                );
              });
          }

          onItemChange({
            ...purchase.workshopPurchaseItems[index],
            ...item,
          });
          setErrors([]);
        }
      })
      .catch((err) => console.error(err));
  };
  const getNextStates = (currentStateId: number): WorkshopPurchaseState[] => {
    return (
      nextStates.find((ns) => ns.state_id == currentStateId)?.next_states || []
    );
  };

  const saveNewPrice = (index: number, mustUsePriceLatestQuote = false) => {
    const newPrice = (
      document.getElementById(
        `item-price-${purchase.workshopPurchaseItems[index].id}`
      ) as any
    )?.value
      .replace("$", "")
      .replaceAll(".", "")
      .replace(",", ".");

    if (
      !newPrice ||
      newPrice == purchase.workshopPurchaseItems[index].price ||
      (!mustUsePriceLatestQuote &&
        newPrice == latestItemPrices[index]?.workshopPurchaseItem.price)
    ) {
      return;
    }

    itemService
      .update(purchase.workshopPurchaseItems[index].id, {
        price: Number(newPrice),
        quantity: Number(purchase.workshopPurchaseItems[index].quantity),
      })
      .then((response) => {
        if ("errors" in response) {
          setErrors(response.errors);
        } else {
          const updatedItem = {
            ...purchase.workshopPurchaseItems[index],
            price: newPrice,
          };
          onItemChange(updatedItem);
          setErrors([]);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDescriptionBlur = async (
    index: number,
    newDescription: string
  ) => {
    if (newDescription == purchase.workshopPurchaseItems[index].description) {
      return;
    }

    const updatedItem = await itemService.update(
      purchase.workshopPurchaseItems[index].id,
      {
        description: newDescription,
      }
    );

    if ("errors" in updatedItem) {
      setErrors(updatedItem.errors);
      return;
    }

    if (errors?.find((e) => e.field === "description")) {
      setErrors(errors.filter((e) => e.field !== "description"));
    }

    onItemChange(updatedItem);
  };

  const handleBrandBlur = async (index: number, e: any) => {
    setErrors(errors?.filter((err) => err.field !== "brand"));

    try {
      const updatedBrand = e.target.value;
      const updatedItem = await itemService.update(
        purchase.workshopPurchaseItems[index].id,
        {
          brand: updatedBrand,
        }
      );

      if ("errors" in updatedItem) {
        setErrors(updatedItem.errors);
      } else {
        const updatedItems = [...purchase.workshopPurchaseItems];
        updatedItems[index] = { ...updatedItems[index], brand: updatedBrand };
        onItemChange(updatedItems[index]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    stateService.getNextStates().then((states) => setNextStates(states));
    new ScrollHint(".js-scrollable");
  }, []);

  useEffect(() => {
    purchase.workshopPurchaseItems.forEach((item, index) => {
      if (
        !purchase.budget.car.carModel?.name ||
        !purchase.budget.car.brand.name ||
        !purchase.budget.car.carVersion?.name ||
        item.workshopPurchaseItemState.id != WorkshopPurchaseState.QUOTE_ID
      ) {
        return;
      }

      itemService.getLatestQuote(item.id).then((res: any) => {
        if (!res?.workshopPurchaseItem?.price) {
          return;
        }

        setLatestItemPrices((prevVal) => {
          const updated = [...prevVal];
          updated[index] = res;

          return updated;
        });
      });
    });
  }, []);

  const getBackgroundColor = (stateId: number): string => {
    switch (stateId) {
      case WorkshopPurchaseItemState.QUOTE_ID:
      case WorkshopPurchaseItemState.BUY_ID:
      case WorkshopPurchaseItemState.MISSING_FROM_FACTORY_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
      case WorkshopPurchaseItemState.IN_STOCK_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
        return "#fef3c7"; //amarillo
      case WorkshopPurchaseItemState.QUOTED_ID:
      case WorkshopPurchaseItemState.LOGISTIC_TRANSIT_ID:
      case WorkshopPurchaseItemState.IN_VERIFICATION_ID:
      case WorkshopPurchaseItemState.DELIVERED_REPAIRED_SAMPLE_ID:
      case WorkshopPurchaseItemState.IN_OUTSOURCED_WORKSHOP_ID:
        return "#dbeafe"; //azul
      case WorkshopPurchaseItemState.DELIVERED_ID:
      case WorkshopPurchaseItemState.FINISHED_ID:
        return "#d1fae5"; //verde
      case WorkshopPurchaseItemState.REJECTED_ID:
        return "#d1d5db"; //gris
      default:
        return "none";
    }
  };

  const getTextColor = (stateId: number): string => {
    switch (stateId) {
      case WorkshopPurchaseItemState.QUOTE_ID:
      case WorkshopPurchaseItemState.BUY_ID:
      case WorkshopPurchaseItemState.MISSING_FROM_FACTORY_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
      case WorkshopPurchaseItemState.IN_STOCK_ID:
      case WorkshopPurchaseItemState.REPLACE_PART_ID:
        return "#d97706";
      case WorkshopPurchaseItemState.QUOTED_ID:
      case WorkshopPurchaseItemState.LOGISTIC_TRANSIT_ID:
      case WorkshopPurchaseItemState.IN_VERIFICATION_ID:
      case WorkshopPurchaseItemState.DELIVERED_REPAIRED_SAMPLE_ID:
      case WorkshopPurchaseItemState.IN_OUTSOURCED_WORKSHOP_ID:
        return "#2563eb";
      case WorkshopPurchaseItemState.DELIVERED_ID:
      case WorkshopPurchaseItemState.FINISHED_ID:
        return "#059669";
      case WorkshopPurchaseItemState.REJECTED_ID:
        return "#6b7280";
      default:
        return "rgba(0, 0, 0, 0.87)";
    }
  };

  return (
    <>
      {isBudgetView ? (
        purchase.is_additional ? (
          <strong>Items adicionales</strong>
        ) : (
          <strong>Items</strong>
        )
      ) : purchase.is_additional ? (
        <strong>Items adicionales a comprar</strong>
      ) : (
        <strong>Items a comprar</strong>
      )}
      <TableContainer
        className="js-scrollable"
        component={Paper}
        sx={{
          margin: "10px 0px 20px 0px",
          minHeight: "120px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ whiteSpace: "nowrap" }}>
              {latestItemPrices.length > 0 && (
                <TableCell align="left">Última cotización</TableCell>
              )}
              <TableCell align="left">Item</TableCell>
              <TableCell align="left">Marca</TableCell>
              <TableCell align="left">Descripción</TableCell>
              <TableCell align="left">Precio</TableCell>
              <TableCell align="left">Cantidad</TableCell>
              <TableCell align="left">Total-Subitem</TableCell>
              <TableCell align="left">Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchase.workshopPurchaseItems.map((item, index) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  whiteSpace: "nowrap",
                }}
              >
                {latestItemPrices.length > 0 && (
                  <TableCell sx={{ fontSize: ".8rem" }}>
                    {latestItemPrices[index]?.date
                      ? new Date(
                          latestItemPrices[index]?.date
                        ).toLocaleDateString()
                      : ""}
                  </TableCell>
                )}
                <TableCell
                  sx={{ fontSize: ".8rem", minWidth: "170px" }}
                  component="th"
                  scope="row"
                  size="small"
                >
                  {item.has_sample ? (
                    <Tooltip title="Muestra disponible">
                      <Box component="span">🔷 </Box>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {item.budgetItem.repairSubitem?.name}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "normal",
                  }}
                >
                  <TextField
                    size="small"
                    fullWidth
                    id={`item-brand-${index}`}
                    defaultValue={item.brand || ""}
                    disabled={
                      isEditionDisabled ||
                      item.budgetItem.repairSubitem.repair_subitem_type_id !=
                        RepairSubitem.REPLACEMENT_TYPE_ID ||
                      isBudgetView
                    }
                    onBlur={(e) => handleBrandBlur(index, e)}
                    InputProps={{
                      sx: {
                        fontSize: ".8rem",
                        fontWeight: "450",
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={item.description} placement="top" arrow>
                    <TextField
                      size="small"
                      disabled={isEditionDisabled || isBudgetView}
                      defaultValue={item.description}
                      onBlur={(e) =>
                        handleDescriptionBlur(index, e.target.value)
                      }
                      error={Boolean(
                        errors && errors.find((e) => e.field === "description")
                      )}
                      helperText={
                        errors?.find((e) => e.field === "description")?.message
                      }
                      InputProps={{
                        sx: {
                          fontSize: ".8rem",
                          fontWeight: "450",
                        },
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <PriceInput
                    id={item.id}
                    price={item.price}
                    lastPrice={
                      latestItemPrices[index]?.workshopPurchaseItem.price
                    }
                    disabled={
                      item.workshopPurchaseItemState.id !==
                        WorkshopPurchaseItemState.QUOTE_ID ||
                      isEditionDisabled ||
                      isBudgetView
                    }
                    onBlur={() => saveNewPrice(index)}
                  />
                </TableCell>
                <TableCell sx={{ fontSize: ".8rem" }}>
                  <FormattedNumber value={item.quantity} />
                </TableCell>
                <TableCell sx={{ fontSize: ".8rem" }}>
                  <FormattedNumber
                    value={itemService.getTotal(item) || 0}
                    style="currency"
                    currency="ARS"
                  />
                </TableCell>
                <TableCell>
                  <FormControl
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      width: "100%",
                      backgroundColor: getBackgroundColor(
                        item.workshopPurchaseItemState.id
                      ),
                    }}
                  >
                    <Tooltip
                      title={
                        item.price
                          ? ""
                          : "Para avanzar de estado se requiere una cotización en el campo PRECIO"
                      }
                      arrow
                    >
                      <Select
                        fullWidth
                        disabled={
                          isEditionDisabled ||
                          isBudgetView ||
                          Boolean(!item.price)
                        }
                        labelId={`state-select-label-${index}`}
                        id={`state-select-${index}`}
                        value={item.workshopPurchaseItemState.id}
                        onChange={(event) => handleStateChange(event, index)}
                        error={Boolean(
                          errors &&
                            errors.find(
                              (e) =>
                                e.field === "workshop_purchase_item_state_id"
                            )
                        )}
                        sx={{
                          ".MuiSelect-icon": {
                            color: isDarkMode
                              ? "#1C252E"
                              : "rgba(0, 0, 0, 0.54)",
                          },
                        }}
                      >
                        {getNextStates(item.workshopPurchaseItemState.id).map(
                          (state) => (
                            <MenuItem
                              key={`state-option-${state.id}`}
                              value={state.id}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "450",
                                  fontSize: ".8rem",
                                  color: getTextColor(state.id),
                                  backgroundColor: getBackgroundColor(state.id),
                                  borderRadius: "5px",
                                  textAlign: "center",
                                  padding: "2px 10px 2px 10px",

                                  "-webkit-text-fill-color": () =>
                                    isEditionDisabled ||
                                    isBudgetView ||
                                    !item.price
                                      ? "#1C252E"
                                      : "inherit",
                                }}
                              >
                                {state.name}
                              </Typography>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Tooltip>

                    {errors &&
                      errors.find(
                        (e) => e.field === "workshop_purchase_item_state_id"
                      ) && (
                        <FormHelperText error={true}>
                          {
                            errors.find(
                              (e) =>
                                e.field === "workshop_purchase_item_state_id"
                            )?.message
                          }
                        </FormHelperText>
                      )}
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
