import { ColumnsPurchase, TypeKanbanPurchase } from "./TypeKanbanPurchase";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseState } from "../../../../entities/workshop-purchase-state.entity";
import { User } from "../../../../entities/user.entity";

interface Color {
  bg: string;
  text: string;
}

const stateColumnMap: { [key: number]: string } = {
  [WorkshopPurchaseState.QUOTE_ID]: WorkshopPurchaseState.QUOTE_LABEL,
  [WorkshopPurchaseState.QUOTED_ID]: WorkshopPurchaseState.QUOTED_LABEL,
  [WorkshopPurchaseState.BUY_ID]: WorkshopPurchaseState.BUY_LABEL,
  [WorkshopPurchaseState.DELIVERED_ID]: WorkshopPurchaseState.DELIVERED_LABEL,
  [WorkshopPurchaseState.FINISHED_ID]: WorkshopPurchaseState.FINISHED_LABEL,
  [WorkshopPurchaseState.SPEC_ID]: WorkshopPurchaseState.SPEC_LABEL,
  [WorkshopPurchaseState.CANCELLED_ID]: WorkshopPurchaseState.CANCELLED_LABEL,
  [WorkshopPurchaseState.EXPIRED_ID]: WorkshopPurchaseState.EXPIRED_LABEL,
  [WorkshopPurchaseState.REJECTED_ID]: WorkshopPurchaseState.REJECTED_LABEL,
};

export const generateColumnsFromPurchases = (
  purchases: WorkshopPurchase[],
  responsibleList: User[]
): ColumnsPurchase => {
  const columns: ColumnsPurchase = {};

  const columnOrder = [
    WorkshopPurchaseState.QUOTE_ID,
    WorkshopPurchaseState.QUOTED_ID,
    WorkshopPurchaseState.BUY_ID,
    WorkshopPurchaseState.DELIVERED_ID,
    WorkshopPurchaseState.FINISHED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
    WorkshopPurchaseState.SPEC_ID,
    WorkshopPurchaseState.REJECTED_ID,
    WorkshopPurchaseState.CANCELLED_ID,
  ];

  const stateColors: { [key: number]: Color } = {
    [WorkshopPurchaseState.QUOTE_ID]: { bg: "#fef3c7", text: "#d97706" }, //Ambar
    [WorkshopPurchaseState.QUOTED_ID]: { bg: "#dbeafe", text: "#2563eb" }, // Blue
    [WorkshopPurchaseState.BUY_ID]: { bg: "#fef3c7", text: "#d97706" }, //Ambar
    [WorkshopPurchaseState.SPEC_ID]: { bg: "#cffafe", text: "#0891b2" }, // Cyan
    [WorkshopPurchaseState.CANCELLED_ID]: { bg: "#d1d5db", text: "#6b7280" }, // Gray
    [WorkshopPurchaseState.DELIVERED_ID]: { bg: "#d1fae5", text: "#059669" }, // Green
    [WorkshopPurchaseState.FINISHED_ID]: { bg: "#d1fae5", text: "#059669" }, // Green
    [WorkshopPurchaseState.EXPIRED_ID]: { bg: "#d1d5db", text: "#6b7280" }, // Gray
    [WorkshopPurchaseState.REJECTED_ID]: { bg: "#d1d5db", text: "#6b7280" }, // Gray
  };

  columnOrder.forEach((stateId) => {
    const stateName = stateColumnMap[stateId];
    columns[stateName] = {
      id: stateId,
      name: stateName,
      items: [],
      color: stateColors[stateId],
    };
  });

  purchases.forEach((purchase) => {
    const stateName = stateColumnMap[purchase.workshopPurchaseState.id];
    const selectedResponsible = responsibleList.find(
      (r) => r.id === purchase.responsible_id
    );

    const task: TypeKanbanPurchase = {
      id: purchase.id.toString(),
      carId: purchase.budget.car.identification,
      brandCar: purchase.budget.car.brand.name.toString(),
      modelCar: purchase.budget.car.carModel?.name.toString(),
      company: purchase.budget.company.name,
      description: purchase.budget.description,
      priorityName: purchase.workshopPurchasePriority?.name,
      priorityId: purchase.workshopPurchasePriority?.id,
      tags: [
        { title: `SC ${purchase.id}`, ...columns[stateName].color },
        {
          title: `PR ${purchase.budget.id}`,
          ...columns[stateName].color,
        },
        purchase.budget.previous_budget_id && {
          title: "R",
          ...columns[stateName].color,
        },
        purchase.is_additional && {
          title: "A",
          ...columns[stateName].color,
        },
      ].filter(Boolean),
      name: selectedResponsible?.name,
      lastName: selectedResponsible?.lastname,
      end_date: purchase.end_date,
      workshopName: purchase.budget.workshop.name,
    };

    columns[stateName].items.push(task);
  });

  return columns;
};
