import { PurchaseContext } from "./PurchaseContext";
import { useState } from "react";
import { PurchaseProps } from "./PurchaseContext";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

interface PurchaseProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const queryClient = useQueryClient();
  const purchaseService = new WorkshopPurchaseService();

  const [open, setOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState<
    undefined | WorkshopPurchase
  >(undefined);
  const [filters, setFilters] = useState<any>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);

  const { data: kanbanPurchases, isLoading: loadingKanbanPurchases } = useQuery(
    {
      queryKey: ["kanbanPurchases"],
      queryFn: () => purchaseService.findAllPurchases(),
      enabled: !!filters,
    }
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["purchases", filters],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await purchaseService.find({
          page: pageParam,
          filters,
        });
        setPageSize(response.pageSize);
        setTotalCount(response.totalCount);
        return response;
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.data.length > 0 ? nextPage : undefined;
      },
      initialPageParam: 1,
    });

  const pagedPurchases = data?.pages.flatMap((page) => page.data) || [];

  const updatePurchase = async (
    purchaseId: number,
    updatedPurchase: Partial<WorkshopPurchase>
  ) => {
    try {
      const updated = await purchaseService.update(purchaseId, updatedPurchase);

      queryClient.setQueryData(["purchases", filters], (oldData: any) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            data: page.data.map((purchase: WorkshopPurchase) =>
              purchase.id === purchaseId ? updated : purchase
            ),
          })),
        };
      });

      queryClient.setQueryData(
        ["kanbanPurchases"],
        (oldData: WorkshopPurchase[] | undefined) => {
          if (!oldData) return oldData;

          return oldData.map((purchase) =>
            purchase.id === purchaseId ? updated : purchase
          );
        }
      );

      if (selectedPurchase?.id === purchaseId) {
        setSelectedPurchase(updated);
      }
    } catch (error) {
      console.error("Error updating purchase:", error);
    }
  };
  const purchaseContextValue: PurchaseProps = {
    open,
    setOpen,
    kanbanPurchases: kanbanPurchases || [],
    selectedPurchase,
    setSelectedPurchase,
    purchases: pagedPurchases,
    setPurchases: () => {},
    loading: isLoading || loadingKanbanPurchases,
    setLoading: () => {},
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    totalCount,
    setFilters,
    pageSize,
    updatePurchase,
  };

  return (
    <PurchaseContext.Provider value={purchaseContextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};
